export class Restrictions {
  RestrictionType: number;
  Who: string;
  UsesNumber: number;
  CostCenter: string;
  AgreementId: string;
  AllowAllCompanies: boolean;

  constructor() {
    this.RestrictionType = null;
    this.Who = null;
    this.UsesNumber = null;
    this.CostCenter = null;
    this.AgreementId = null;
  }
}
