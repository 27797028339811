import {QuestionModel} from "./question.model";

export class  ResultModel {
  AlertCode: string;
  AlertResponse: string;
  Alerts: string;
  AnnualNumberOfIntents: number;
  DailyNumberOfIntents: number;
  ExcludeClient: boolean;
  Id: string;
  Questions: QuestionModel[];
  MonthlyNumberOfIntents: number;
  Record: string;
  Result: string;
  Subtip: string;
  MaximumAttemptsPerDay: boolean;
  MaximumAttemptsPerMonth: boolean;
  MaximumAttemptsPerYear: boolean;
  NotEnoughQuestions: boolean;
  MaximumAttemptsPerProductDay: boolean;
  MaximumAttemptsPerProductMonth: boolean;
  MaximumAttemptsPerProductYear: boolean;
  UnAuthorizedT1: boolean;
  UnAuthorizedT2: boolean;
  UnAuthorizedT3: boolean;
  Success: boolean;
}
