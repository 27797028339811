import {Injectable} from '@angular/core';
import {Service} from '../service';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpResponse } from "@angular/common/http";
import {Observable} from 'rxjs';
import {catchError, tap, map} from 'rxjs/operators';
import {CoreConfig} from "../../models/core.config";
import { PrestaClicModel, QueryHistoryModel } from "../../models";

@Injectable()
export class QueryHistoryService extends Service<QueryHistoryModel> {

  public url = 'v1/QueryHistory/';

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

  public queryHistory(page: number, pageSize: number, startDate: string, endDate: string, productId?: string, enterpriseId?: string): Observable<QueryHistoryModel[]> {

    let uri = `${this.apiUrl}${this.url}?ProductId=${productId}&EnterpriseId=${enterpriseId}&Page=${page}&PageSize=${pageSize}&StartDate=${startDate}&EndDate=${endDate}`;
    if (enterpriseId === undefined || enterpriseId === '') {
      uri = `${this.apiUrl}${this.url}?ProductId=${productId}&Page=${page}&PageSize=${pageSize}&StartDate=${startDate}&EndDate=${endDate}`;
    }
    return <any> this.http
      .get(uri)
      .pipe(
        tap(() => {}),
        catchError(this.handleErrors)
      );
  }

  public queryHistoryReportFile(queryId: string): Observable<QueryHistoryModel[]> {
    const uri = `${this.apiUrl}${this.url}?queryId=${queryId}/ReportFile`;
    return <any> this.http
      .get(uri)
      .pipe(
        tap(() => {}),
        catchError(this.handleErrors)
      );
  }

  public lastQuery(productId?: string, enterpriseId?: string): Observable<QueryHistoryModel> {
    const uri = `${this.apiUrl}${this.url}LastQuery/?ProductId=${productId}&EnterpriseId=${enterpriseId}`;
    return <any> this.http
      .get(uri)
      .pipe(
        tap(() => {}),
        catchError(this.handleErrors)
      );
  }

  /*Last Queries Group By Product*/
  public lastQueriesGroupByProduct(orderBy: string, query: string, productId?: string, enterpriseId?: string, page?: any, pageSize?: any): Observable<QueryHistoryModel[]> {
    const uri = `${this.apiUrl}${this.url}LastQueriesGroupByProduct?ProductId=${productId}&EnterpriseId=${enterpriseId}&OrderBy=${orderBy}&Query=${query}&Page=${page}&PageSize=${pageSize}`;
    return <any> this.http
      .get(uri)
      .pipe(
        tap(() => {}),
        catchError(this.handleErrors)
      );
  }

  public consolidateFileByEnterprises(EnterpriseId: string): Observable<any> {
    const uri = `${this.apiUrl}${this.url}ConsolidateFileByEnterprise/${EnterpriseId}`;
    return <any> this.http
      .get(uri, {responseType: "blob"})
      .pipe(
        tap(() => {}),
        catchError(this.handleErrors)
      );
  }

  /**
   *
   * param {string} EnterpriseId
   * returns {Observable<any>}
   * Descarga de reporte Excel directorio empresarial
   */
  public downloadFileDE(Id: string): Observable<any> {
    const uri = `${this.apiUrl}${this.url}${Id}/ReportFile`;
    return <any> this.http.get(uri, {responseType: "blob", observe: 'response'}).pipe(
        tap(result => result),
        catchError(this.handleErrors)
      )
  }

  public getClicservise(idQuery?: any ): Observable<PrestaClicModel> {
    return <any> this.http
      .get(`${this.apiUrl}${this.url}GetClicByQueryHistoryId/${idQuery}`)
      .pipe(
        tap(() => {}),
        catchError(this.handleErrors)
      );
  }
}
