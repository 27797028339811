import {Injectable} from '@angular/core';
import {Service} from '../../services/service';
import {HttpClient} from '@angular/common/http';
import {CoreConfig} from "../../models/core.config";
import {RolesModel} from "./../../modelsB/";

@Injectable()
export class RolesService extends Service<RolesModel> {

  public url = 'v1/role/';

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

}
