import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import { ConsumptionPerClientReportModel } from './../../modelsB/';
import { CoreConfig } from "../../models/core.config";
import { Service } from "../../services";

const httpOptionsFile = {
  headers: new HttpHeaders({'Content-Type': 'application/json', responseType: 'blob'})
};

@Injectable()
export class ConsumptionsPerCustomerReportService  extends Service<ConsumptionPerClientReportModel> {
  protected url =  'v1/PurchaseReport/ReportPackageOfClientByDateRanges';
  lastData: any;

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

  /**
   * Reporte de consumo por cliente filtrado por fecha
   * @param initdate 
   * @param endDate 
   */
  getReportConsumptionsPerCustomer(initdate: any, endDate: any): Observable<ConsumptionPerClientReportModel>  {
    return this.http.get<ConsumptionPerClientReportModel>(`${this.apiUrl}${this.url}?StartDate=${initdate}&EndDate=${endDate}`).pipe(
      map(result => result),
      tap((result) => {
        this.lastData = result;
      }),
      catchError(this.handleErrors)
    );
  }

  /**
   * Traer datos de reporte para despues descargar en excel
   * @param initdate 
   * @param endDate 
   */
  downloadReportConsumptionsPerCustomer(initdate: any, endDate: any): Observable<any> {
    // Este servicio cambia en los headers porque tiene que ser tipo Blob para poder comvertir el json a excel en el subscribe
    return this.http.get<ConsumptionPerClientReportModel>(`${this.apiUrl}${this.url}?StartDate=${initdate}&EndDate=${endDate}`, httpOptionsFile).pipe(
      map(result => result),
      tap((result) => {
        this.lastData = result;
      }),
      catchError(this.handleErrors)
    );
  }
}
