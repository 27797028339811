import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { ProductModels } from '../../modelsB/';
import {catchError, map, tap} from 'rxjs/operators';
import { CoreConfig } from "../../models/core.config";
import { Service } from "../../services";

@Injectable()
export class ProductsService extends Service<ProductModels> {
  protected url = 'v1/Product/';
  lastData: any;

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

}
