import {Injectable} from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Service } from "../service";
import { CoreConfig, PurchaseModel, PurchaseResult } from "../../models";
import { Observable } from "rxjs";
import { catchError, tap } from "rxjs/operators";

@Injectable()

export class ExperianService extends Service<any> {

  public url = 'v1/Experian/';

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }


  /**
   * Traer las listas restrictivas
   * parametro lastName apellido
   * parametro identification identificacion
   * parametro identificationType tipo de identificacion
   * parametro enterpriseId id de la empresa
   */
  public restrictiveList(lastName?: any, identification?: any, identificationType?: any, enterpriseId?: any ): Observable<any> {
    let params = new HttpParams();
    params = params.append( 'LastName', lastName );
    params = params.append('Identification', identification );
    params = params.append( 'IdentificationType', identificationType );
    params = params.append( 'EnterpriseId', enterpriseId );
    return <any> this.http
      .get(`${this.apiUrl}${this.url}RestrictiveList/?`, {params : params})
      .pipe(
        tap(() => {}),
        catchError(this.handleErrors)
      );
  }

  /**
   * Traer el score de la mempresa
   * parametro enterpriseId id de la empresa
   */
  public getScoreBi(enterpriseId: any ): Observable<any> {
    return <any> this.http
      .get(`${this.apiUrl}${this.url}Score/${enterpriseId}`)
      .pipe(
        tap(() => {}),
        catchError(this.handleErrors)
      );
  }

  /**
   * Traer producto prestaclic del usuario
   */
  public getClic(lastName?: any, identification?: any, identificationType?: any, enterpriseId?: any ): Observable<any> {
    let params = new HttpParams();
    params = params.append( 'LastName', lastName );
    params = params.append('Identification', identification );
    params = params.append( 'IdentificationType', identificationType );
    params = params.append( 'EnterpriseId', enterpriseId );
    return <any> this.http
      .get(`${this.apiUrl}${this.url}Clic/?`, {params : params})
      .pipe(
        tap(() => {}),
        catchError(this.handleErrors)
      );
  }
}
