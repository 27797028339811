export class QueryPurchaseModel {

  TotalAmount: number;
  TransactionState: string;
  TransactionId: string;
  TrazabilityCode: string;
  BankName: string;
  PayCurrency: string;
}

