export class EnterpriseRuesModel {
  Name: string;
  CompleteNit: string;
  Category: string;
  Nit: string;
  Code: string;
  MercantilRegister: {
    codigo_camara: string;
    matricula: string;
  };
}
