import {AccessGrantModels} from './accessGrant.model';
export class RolesModel {
  Id: string;
  Name: string;
  CreateBy: string;
  CreationDate: Date;
  Policies: string;
  Permission: AccessGrantModels;

  constructor() {
    this.Id = null;
    this.Name = null;
    this.CreateBy = null;
    this.CreationDate = new Date();
    this.Policies = null;
    this.Permission = null;
  }
}
