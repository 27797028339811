import {MetadataModel} from '../metadata.model';

export class ProductModel {
  Description: string;
  Id: string;
  CorrelationCode: {
    correlationId: string,
    correlationCompanyId: string
  };
  Name: string;
  ProductBenefits?: MetadataModel[];
  Containers: [{
    Title: string,
    SubTitle: string,
    Description: string,
    AssociatedImage: string
  }];
  ProductStatus: string;
  CreationDate: string;
  CreatedBy: string;
  InitialValidityDate: string;
  FinalValidityDate: string;
  LinkDemo: string;
  Title: string;
  PackageHeader: string;
  DescriptionImage: string;
  BenefitsImage: string;
  DescriptionSlide: string;
  UrlActionSlide: string;
  SlideImage: string;
  ActionText: string;
  ProductFunctionalities?: MetadataModel[];
  AvailableForEnterprise: boolean;


}
