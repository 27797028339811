export  class AccessGrantModel {
  Id: string;
  Description: string;
  IsAccepted: boolean;

  constructor() {
    this.Id = null;
    this.Description = null;
    this.IsAccepted = false;
  }
}
