export enum ResultErrorModel {
  "ContinÃºe",
  "No ContinÃºe",
  "Error"
}
export enum StatusReportModel {
  "Aprobado",
  "No aprobado",
  "Pendiente"
}
export enum ResultsScore {
  'Exitoso',
  'Error'
}
export enum docType {
  'Nit' = 2,
  'CÃ©dula de ciudadanÃ­a' = 1
}
