export class PackagesModel {
  Id: string;
  Name: string;
  ExperianPrice: number;
  ClientPrice: number;
  BanlineaRate: number;
  ClientPriceWithTax: number;
  ComparativePrice: number;
  TaxId: string;
  AmountConsults: number;
  ProductId: number;
  Benefits: any[];
  CreationDate: Date;
  PackageCode: number;
  TextAmount: string;
  Order: number;
  ExperianName: string;
  Status: string;
  WhiteBackground: boolean;
  AvailableForEnterprise: boolean;
  ProductName: string;
  CreateBy?: string;
  State: any;
  TransactionType: string;

  constructor() {
    this.Name = null;
    this.ExperianPrice = null;
    this.ClientPrice = null;
    this.BanlineaRate = null;
    this.ClientPriceWithTax = null;
    this.ComparativePrice = 0;
    this.TaxId = '00000000-0000-0000-0000-000000000000';
    this.AmountConsults = null;
    this.ProductId = null;
    this.Benefits = [];
    this.PackageCode = null;
    this.TextAmount = null;
    this.Order = null;
    this.ExperianName = null;
    this.WhiteBackground = false;
    this.AvailableForEnterprise = false;
    this.ProductName = null;
    this.CreationDate = null;
    this.State = 1;
    this.TransactionType = null;
  }
}

export enum PackageStatus {
  Inactivo,
  Activo
}
export class Benefit {
  Description: string;
  constructor () {
    this.Description = null;
  }
}
export enum PackageCode {
  Standard,
  Premium,
  Ultimate
}
