import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import {catchError, map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import { DetailedMaturityReportModel } from './../../modelsB/';
import { CoreConfig } from "../../models/core.config";
import { Service } from "../../services";

const httpOptionsFile = {
  headers: new HttpHeaders({'Content-Type': 'application/json', responseType: 'blob'})
};

@Injectable()
export class DetailedMaturityReportService  extends Service<DetailedMaturityReportModel> {
  protected url = 'v1/PurchaseReport/GetPackageExpiredDetailByDateRange';
  lastData: any;

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

  /**
   * Traer datos por paginado de los reportes de vencimiento
   * @param initdate 
   * @param endDate 
   * @param page 
   * @param pageSize 
   */
  getReportDetailedMaturity(initdate: any, endDate: any, page?: any, pageSize?: any): Observable<DetailedMaturityReportModel>  {
    let params = new HttpParams();
    params = params.append('StartDate', initdate);
    params = params.append('EndDate', endDate);
    params = params.append('Page', page);
    params = params.append( 'PageSize', pageSize);
    return this.http.get<DetailedMaturityReportModel>(`${this.apiUrl}${this.url}?`, {params : params}).pipe(
      map(result => result),
      tap((result) => {
        this.lastData = result;
      }),
      catchError(this.handleErrors)
    );
  }

  /**
   * Traer datos para descargar en excel
   * @param initdate 
   * @param endDate 
   * @param page 
   * @param pageSize 
   */
  downloadReportDetailedMaturity(initdate: any, endDate: any, page?: any, pageSize?: any): Observable<any> {
    let params = new HttpParams();
    params = params.append('StartDate', initdate);
    params = params.append('EndDate', endDate);
    params = params.append('Page', page);
    params = params.append( 'PageSize', pageSize);
    // Este servicio cambia en los headers porque tiene que ser tipo Blob para poder comvertir el json a excel en el suscribe
    return this.http.get<DetailedMaturityReportModel>(`${this.apiUrl}${this.url}?`, {params : params}).pipe(
      map(result => result),
      tap((result) => {
        this.lastData = result;
      }),
      catchError(this.handleErrors)
    );
  }
}
