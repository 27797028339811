export class BasicInformationModel {
    nombreCompleto:string;
    numeroDocumento:number;
    estadoDocumento:string;
    rangoEdad:string;
    genero:string;
    lugarExpedicion:string;
    fechaExpedicion:Date;

  constructor() {
     this.nombreCompleto = null;
     this.numeroDocumento = null;
     this.estadoDocumento = null;
     this.rangoEdad = null;
     this.genero = null;
     this.lugarExpedicion = null;
     this.fechaExpedicion = null;
  }
}
