import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class DownloadReportService {

  constructor() {}

  /**
   * Crear archivo de excel con una cadena json
   */
  public downloadFile(json: any[], excelFileName: string, dateInit?: any, dateEnd?: any): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFiles(excelBuffer, excelFileName, dateInit, dateEnd);
  }

  /**
   * Descargar archivo
   */
  private saveAsExcelFiles(buffer: any, fileName: string, dateInit: any, dateEnd: any): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + dateInit + dateEnd + EXCEL_EXTENSION);
  }

}


