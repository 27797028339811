export class UpdateQueryModel {
  product: {
    id: string;
    name: string;
    packageId: string;
  };
  enterprise: {
    id: string;
    name: string;
  };
  enterpriseConsultedDni: string;
  enterpriseConsultedName: string;
  enterpriseConsultedIndustry: string;
  enterpriseConsultedUbication: string;
  enterpriseConsultedDescription: string;
  consultOriginIp: string;
}
