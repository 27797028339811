import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { ListProductModel } from '../../modelsB/';
import {catchError, map, tap} from 'rxjs/operators';
import { CoreConfig } from "../../models/core.config";
import { Service } from "../../services";

@Injectable()
export class ListProductsService extends Service<ListProductModel> {
  protected url = 'v1/Product/';
  lastData: any;

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

  getProducts(page: number, pageSize: number): Observable<ListProductModel>  {
    return this.http.get<ListProductModel>(`${this.apiUrl}${this.url}?Page=${page}&PageSize=${pageSize}`).pipe(
      map(result => result),
      tap((result) => {
        this.lastData = result;
      }),
      catchError(this.handleErrors)
    );
  }
}
