export class PackageModel {
  Id: string;
  Name: string;
  ClientPriceWithTax: number;
  Comparative: number;
  AmountConsults: number;
  TaxId: string;
  ProductId: string;
  Benefits: string[];
  CreationDate: string;
  Selected: boolean;
  AvailableForEnterprise: boolean;
  WhiteBackground: boolean;
}
