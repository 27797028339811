import {PackageModel} from "../";
import {ConsultedEnterpriseModel} from "./consultedEnterprise.model";

export class PurchaseModel {
  EnterpriseId?: string;
  EnterpriseName?: string;
  RedirectUrl?: string;
  PurchaseOriginIp?: string;
  Packages?: Partial<PackageModel>[];
  SubTotalAmount?: number;
  TotalAmount?: number;
  DiscountAmount?: number;
  DiscountCode?: string;
  ConsultedEnterprises?: Partial<ConsultedEnterpriseModel>[];
}

