import { Injectable } from "@angular/core";
import { ForgotPasswordModel, RegisterModel } from "../../models";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CoreConfig } from "../../models/core.config";
import { Observable } from "rxjs";
import { JwksValidationHandler, OAuthService } from "angular-oauth2-oidc";
import { ServiceBackOffice } from "../service-backOffice";

@Injectable()

export class AuthBackOfficeService extends ServiceBackOffice<RegisterModel> {

  public url = "v1/AuthBackOffice/";
  headers = new HttpHeaders().set("Content-Type", "application/json; charset=utf-8");

  constructor(http: HttpClient, config: CoreConfig, private oauthService: OAuthService) {
    super(http, config);
   // this.configure();
  }

  // private configure() {
  //   this.oauthService.configure(this.authConfig);
  //   this.oauthService.tokenValidationHandler = new JwksValidationHandler();
  //   this.oauthService.loadDiscoveryDocumentAndTryLogin();
  // }


  public login() {
    this.oauthService.initImplicitFlow();
    // this.oauthService.refreshToken()
  }
  public generatePasswordToken(email: string, typeTemplate?: number): Observable<{ Message: string, EmailSent: boolean }> {
    const uri = `${this.apiUrl}${this.url}GeneratePasswordToken?email=${email}&typeTemplate=${typeTemplate}`;
    return this.http.post<{ Message: string, EmailSent: boolean }>(uri, "", { headers: this.headers });
  }

  public validatePasswordToken(email: string, token: string): Observable<{ Message: string, TokenIsValid: boolean }> {
    const uri = `${this.apiUrl}${this.url}ValidatePasswordToken?email=${email}&token=${token}`;
    return this.http.post<{ Message: string, TokenIsValid: boolean }>(uri, "", { headers: this.headers });
  }

  public forgotPassword(forgotPasswordForm: ForgotPasswordModel): Observable<{ Message: string, ChangePassword: boolean }> {
    const uri = `${this.apiUrl}${this.url}ForgotPassword`;
    return this.http.post<{ Message: string, ChangePassword: boolean }>(uri, forgotPasswordForm, { headers: this.headers });
  }
}
