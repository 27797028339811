import {Injectable} from '@angular/core';
import {Service} from '../service';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {CoreConfig} from "../../models/core.config";
import {PurchaseIntentModel} from "../../models/index";

@Injectable()
export class PurchaseIntentService extends Service<PurchaseIntentModel> {

  public url = 'v1/PurchaseIntent/';

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

  public getPurchaseIntent(enterpriseId: string, userId: string, purchaseIntentId: string): Observable<PurchaseIntentModel[]> {
    const uri = `${this.apiUrl}${this.url}?EnterpriseId=${enterpriseId}&UserId=${userId}&PurchaseIntentId=${purchaseIntentId}`;
    return <any> this.http
      .get(uri)
      .pipe(
        tap(() => {}),
        catchError(this.handleErrors)
      );
  }

  public purchaseIntent(obj: PurchaseIntentModel): Observable<PurchaseIntentModel[]> {
    const uri = `${this.apiUrl}${this.url}`;
    return <any> this.http
      .post(uri, obj)
      .pipe(
        tap(() => {}),
        catchError(this.handleErrors)
      );
  }

}
