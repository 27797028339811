import { BasicInformationModel } from "./basicInformation.model";
import { RiskPolicySMEModel } from "./riskPolicySME.model";
import { CollectionStrategyModel } from "./collectionStrategy.model";
import { AlertsModel } from "./alerts.model";

export class PrestaClicModel {
  fechaConsulta: Date;
  codigo: string;
  mensaje: string;
  informacionBasica: BasicInformationModel;
  politicaRiesgoSME: RiskPolicySMEModel;
  estrategiaRecau2: CollectionStrategyModel;
  alertas: AlertsModel[];

  constructor() {
     this.fechaConsulta = null;
     this.codigo = null;
     this.mensaje = null;
     this.informacionBasica = new BasicInformationModel();
     this.politicaRiesgoSME = new RiskPolicySMEModel();
     this.estrategiaRecau2 = new CollectionStrategyModel();
     this.alertas = new Array<AlertsModel>();
  }
}
