export class EnterpriseFilterModel {
  Id: string;
  Name: string;
  Nit: string;
  UrlLogo: string;

  constructor() {
    this.Id = null;
    this.Name = null;
    this.Nit = null;
    this.UrlLogo = null;
  }
}
