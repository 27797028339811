import { EventEmitter, Injectable } from '@angular/core';
import { ServiceBackOffice } from './service-backOffice';
import { HttpClient } from '@angular/common/http';

import { Observable ,  BehaviorSubject ,  Subject } from 'rxjs';
import { CoreConfig } from "../models/core.config";
// import { catchError, map, tap } from 'rxjs/operators';

// import { of } from 'rxjs/observable/of';

@Injectable()
export class BidirectionalService extends ServiceBackOffice<any> {
  
  private type: Subject<any> = new BehaviorSubject<any>('');
  public refresh: Subject<boolean> = new Subject<boolean>();
  type$ = this.type.asObservable();
  private tab = new Subject<any>();
  private swichFooter = new Subject<any>();
  private code = new Subject<any>();

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

  onGetData: EventEmitter<any> = new EventEmitter();

  sendTab(tabs: any) {
    this.tab.next(tabs);
  }

  getTab(): Observable<any> {
    return this.tab.asObservable();
  }

  sendFooter(val: any) {
    this.swichFooter.next(val);
  }

  getFooter(): Observable<any> {
    return this.swichFooter.asObservable();
  }

  sendHeader(val: any) {
    this.swichFooter.next(val);
  }

  getHeader(): Observable<any> {
    return this.swichFooter.asObservable();
  }

  sendCode(code: any) {
    this.code.next(code);
  }

  getCode(): Observable<any> {
    return this.code.asObservable();
  }

  clearStep() {
    this.tab.next();
  }

}
