export class RestrictiveListModel {
  Id: string;
  Date: Date;
  Hour: string;
  DocumentType: string;
  DocumentNumber: number;
  EnterpriseName: string;
  Result: string;
  Description: string;
  Status: string;

  constructor() {
    this.Id = null;
    this.Date = null;
    this.Hour = null;
    this.DocumentType = null;
    this.DocumentNumber = null;
    this.EnterpriseName = null;
    this.Result = null;
    this.Description = null;
    this.Status = null;
  }
}
