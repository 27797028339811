import { Injectable } from "@angular/core";
import { Service } from "../service";
import { ForgotPasswordModel, RegisterModel } from "../../models";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { CoreConfig } from "../../models/core.config";
import { catchError, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { JwksValidationHandler, OAuthService } from "angular-oauth2-oidc";

@Injectable()

export class AuthService extends Service<RegisterModel> {

  public url = 'v1/Auth/';
  headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');


  constructor(http: HttpClient, config: CoreConfig, private oauthService: OAuthService) {
    super(http, config);
    // this.configure();
  }

  /**
   * Registrar
   */
  public register(register: RegisterModel): Observable<RegisterModel[]> {
    const uri = `${this.apiUrl}${this.url}Register`;
    const body = JSON.stringify(register);
    return this.http
      .post<RegisterModel[]>(uri, body, { headers: this.headers })
      .pipe(
        tap(() => { }),
        catchError(this.handleErrors)
      );
  }

  /**
   * Relogearse
   */
  public login() {
    this.oauthService.initImplicitFlow();
    // this.oauthService.refreshToken()
  }

  /**
   * Deslogearse
   */
  public logout() {
    this.oauthService.logOut();
  }

  /**
   * Validar datos del registro
   */
  public validDataRegister(identificationType: any, identificationNumber: any, email: any): Observable<any> {
    let params = new HttpParams();
    params = params.append('identificationType', identificationType);
    params = params.append('identificationNumber', identificationNumber);
    params = params.append('email', email);
    return <any>this.http
      .get(`${this.apiUrl}${this.url}VerifyRegisterAvailability`, { params: params })
      .pipe(
        tap(() => { }),
        catchError(this.handleErrors)
      );
  }

  /**
   * Configurar servicio de autentificacion
   */
  // private configure() {
  //   this.oauthService.configure(this.authConfig);
  //   this.oauthService.tokenValidationHandler = new JwksValidationHandler();
  //   this.oauthService.loadDiscoveryDocumentAndTryLogin();
  // }

  /**
   * Generar contraseÃ±a del toquen para realizar el login
   */
  public generatePasswordToken(email: string): Observable<{ Message: string, EmailSent: boolean }> {
    const uri = `${this.apiUrl}${this.url}GeneratePasswordToken?email=${email}`;
    return this.http.post<{ Message: string, EmailSent: boolean }>(uri, '', { headers: this.headers });
  }

  /**
   * Validar contraseÃ±a generada
   */
  public validatePasswordToken(email: string, token: string): Observable<{ Message: string, TokenIsValid: boolean }> {
    const uri = `${this.apiUrl}${this.url}ValidatePasswordToken?email=${email}&token=${token}`;
    return this.http.post<{ Message: string, TokenIsValid: boolean }>(uri, '', { headers: this.headers })
  }

  /**
   * Verificar token de cambio de contraseÃ±a
   */
  public forgotPassword(forgotPasswordForm: ForgotPasswordModel): Observable<{ Message: string, ChangePassword: boolean }> {
    const uri = `${this.apiUrl}${this.url}ForgotPassword`;
    return this.http.post<{ Message: string, ChangePassword: boolean }>(uri, forgotPasswordForm, { headers: this.headers })
  }

}
