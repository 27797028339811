import {Imgbase64Model} from './imgbase64.model';

export class BenefitsModel {
  Description: string;
  IconBase64: string;
  Icon: string;
  constructor () {
    this.Description = null;
    this.IconBase64 = null;
    this.Icon = null;
  }
}
