export class BusinessDirectoryModel {
  TotalLength: number;
  BusinessDirectory: [{
    Name: string,
    City: string,
    CreationDate: string,
    Industry: string,
    Description: string,
    Nit: string,
    Logo: string
  }];
}
