import {Injectable} from '@angular/core';
import {Service} from '../service';
import {HttpClient} from '@angular/common/http';
import {CoreConfig} from '../../models/core.config';
import {catchError, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import { UserModel } from "../../models/User/user.model";

@Injectable()
export class UserService extends Service<UserModel> {

  public url = 'v1/User/';

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

  /**
   * verificar numero en la base de datos
   */
  public phoneNumberAvailable(phoneNumber: string): Observable<string> {
    const uri = `${this.apiUrl}${this.url}PhoneNumberAvailable/${phoneNumber}`;
    return <any> this.http
      .get(uri)
      .pipe(
        tap(() => {}),
        catchError(this.handleErrors)
      );
  }

  /**
   * 
   */
  public mailAvailable(mail: string): Observable<string> {
    const uri = `${this.apiUrl}${this.url}MailAvailable/${mail}`;
    return <any> this.http
      .get(uri)
      .pipe(
        tap(() => {}),
        catchError(this.handleErrors)
      )
  }
}
