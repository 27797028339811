import {Injectable} from "@angular/core";
import {Service} from "../service";
import {ChamberCommerceModel} from "../../models";
import {HttpClient} from "@angular/common/http";
import {CoreConfig} from "../../models/core.config";
import {catchError, tap} from "rxjs/operators";
import {Observable} from "rxjs";

@Injectable()

export class ChamberCommerceService extends Service<ChamberCommerceModel> {

  public url = 'v1/ChamberCommerce/';

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

  public getChamberCommerce(): Observable<ChamberCommerceModel[]> {

    const uri = `${this.apiUrl}${this.url}`;

    return this.http
      .get<ChamberCommerceModel[]>(uri)
      .pipe(
        tap(() => {}),
        catchError(this.handleErrors)
      );
  }
}
