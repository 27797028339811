import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import { ExpirationReportModel } from './../../modelsB/';
import { CoreConfig } from "../../models/core.config";
import { Service } from "../../services";

const httpOptionsFile = {
  headers: new HttpHeaders({'Content-Type': 'application/json', responseType: 'blob'})
};

@Injectable()
export class ExpirationsReportService  extends Service<ExpirationReportModel> {
  protected url = 'v1/PurchaseReport/ReportGetAmountExpiredPackageByDateRange';
  lastData: any;

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

  /**
   * Traer reporte de expiracion
   * @param initdate 
   * @param endDate 
   */
  getReportExpirations(initdate: any, endDate: any): Observable<ExpirationReportModel>  {
    return this.http.get<ExpirationReportModel>(`${this.apiUrl}${this.url}?StartDate=${initdate}&EndDate=${endDate}`).pipe(
      map(result => result),
      tap((result) => {
        this.lastData = result;
      }),
      catchError(this.handleErrors)
    );
  }

  /**
   * Traer datos de reporte para descargar datos en excel
   * @param initdate 
   * @param endDate 
   */
  downloadReportExpirations(initdate: any, endDate: any): Observable<any> {
    // Este servicio cambia en los headers porque tiene que ser tipo Blob para poder comvertir el json a excel en el suscribe
    return this.http.get<ExpirationReportModel>(`${this.apiUrl}${this.url}?StartDate=${initdate}&EndDate=${endDate}`, httpOptionsFile).pipe(
      map(result => result),
      tap((result) => {
        this.lastData = result;
      }),
      catchError(this.handleErrors)
    );
  }
}
