export class Identification {
  NumberId: string;
  IdentificationType: number;
  ExpeditionDate: string;
  ExpeditonPlaceId: string;

  constructor() {
    this.NumberId = null;
    this.IdentificationType = null;
    this.ExpeditionDate = null;
    this.ExpeditonPlaceId = null;
  }
}
