export class DetailedConsumptionReportModel {
  Id: string;
  PurchaseDate: Date;
  EnterpriseId: string;
  ClientName: string;
  PackageName: string;
  PurchaseId: string;
  RegisterNumber: string;
  NumberPackagesSold: number;
  Discount: number;
  ExperianPrice: number;
  ClientPrice: number;
  NumberConsumptions: number;
  UnitExperianPrice: number;
  UnitClientPrice: number;
  UnitClientPriceWithOutTax: number;
  TotalExperianPrice: number;
  TotalClientPrice: number;
  CreateDate: Date;
  ExpiredDate: Date;
  EnterpriseNit: string;
  QueryDate: Date;

  constructor() {
    this.Id = null;
    this.PurchaseDate = null;
    this.EnterpriseId = '00000000-0000-0000-0000-000000000000';
    this.ClientName = null;
    this.PackageName = null;
    this.PurchaseId = '00000000-0000-0000-0000-000000000000';
    this.RegisterNumber = null;
    this.NumberPackagesSold = 0;
    this.Discount = 0;
    this.ExperianPrice = 0;
    this.ClientPrice = 0;
    this.NumberConsumptions = 0;
    this.UnitExperianPrice = 0;
    this.UnitClientPrice = 0;
    this.UnitClientPriceWithOutTax = 0;
    this.TotalExperianPrice = 0;
    this.TotalClientPrice = 0;
    this.CreateDate = new Date();
    this.ExpiredDate = null;
    this.EnterpriseNit = null;
    this.QueryDate = null;
  }
}
