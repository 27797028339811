export class QueryHistoryModel {
  Id: string;
  Product: { Id: string, Name: string, PackageId: string, TextAmountConsults: string, AmountCounsults: number };
  Enterprise: { Id: string, Name: string };
  EnterpriseConsultedDni: string;
  EnterpriseConsultedName: string;
  EnterpriseConsultedIndustry: string;
  EnterpriseConsultedUbication: string;
  EnterpriseConsultedDescription: string;
  QueryDate: string;
  ConsultOriginIp: string;
  ReportPath: string;
  RadicationId: string;
  FileType: string;
  State: string;
  ProductCode: number;
  PackageName: string
  ValidateMinutes: boolean;
}
