import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import {Observable, of, throwError as _throw,  throwError } from 'rxjs';
import {catchError, tap, } from 'rxjs/operators';
import {CoreConfig} from "../models";
import swal from 'sweetalert2';
export abstract class Service<T> {
  protected url: string;
  protected readonly apiUrl: string;

  constructor(protected http: HttpClient, config: CoreConfig ) {
    this.apiUrl = config.apiUrl;
  }

  public get(query?: Partial<T>, select?: string[], page?: any, pageSize?: any): Observable<T[]> {
    let params = new HttpParams();
    if (query) {
      params = params.append('query', JSON.stringify(query));
    }
    if (select) {
      params = params.append('select', select.join(','));
    }
    params = params.append('Page', page);
    params = params.append( 'PageSize', pageSize);
    return <any> this.http
      .get( `${this.apiUrl}${this.url}`, {params : params})
      .pipe(
      tap(() => {}),
      catchError(this.handleErrors)
    );
  }
  public getPages(query?: any, select?: string[], page?: any, pageSize?: any): Observable<T[]> {
    let params = new HttpParams();
    if (select && query) {
      params = params.append('query.EnterpriseId', query);
      params = params.append('select', select.join(','));
      params = params.append('Page', page);
      params = params.append( 'PageSize', pageSize);
    }
    return <any> this.http
      .get( `${this.apiUrl}${this.url}?`, {params : params})
      .pipe(
        tap(() => {}),
        catchError(this.handleErrors)
      );
  }

  public getPagesDimanic(textQuery?: any[] ,query?: any[], select?: string[], page?: any, pageSize?: any): Observable<T[]> {
    let params = new HttpParams();
    if (select) {
      params = params.append('select', select.join(','));
      params = params.append('Page', page);
      params = params.append( 'PageSize', pageSize);
    }
    if (textQuery && query) {
      for (let i = 0; i < query.length; i++) {
        params = params.append(textQuery[i], query[i]);
      }
      params = params.append('Page', page);
      params = params.append( 'PageSize', pageSize);
    }
    return <any> this.http
      .get( `${this.apiUrl}${this.url}?`, {params : params})
      .pipe(
        tap(() => {}),
        catchError(this.handleErrors)
      );
  }

  public getById(id: any): Observable<T> {
    return <any> this.http
      .get(`${this.apiUrl}${this.url}${id}`);
  }

  public post(obj?: Partial<T>): Observable<T> {
      return  this.http.post<any>(`${this.apiUrl}${this.url}`, obj ).pipe(
        tap(() => {}),
        catchError(this.handleErrors)
      );
  }

  public postObj(obj?: Partial<T>): Observable<T> {
      return  this.http.post<any>(`${this.apiUrl}${this.url}`, obj ).pipe(
        tap(() => {}),
        catchError(this.handleErrors)
      );
  }

  public update(obj?: Partial<T>): Observable<T> {
    return <any> this.http
      .put(`${this.apiUrl}${this.url}`, obj)
      .pipe(
        tap(() => {}),
        catchError(this.handleErrors)
      );
  }

  /* Update Id */
  public updateId(Id: any, obj?: Partial<T>): Observable<T> {
    return <any> this.http
      .put(`${this.apiUrl}${this.url}${Id}`, obj)
      .pipe(
        tap(() => {}),
        catchError(this.handleErrors)
      );
  }
  /* patch */
  public updatePartial(obj?: Partial<T>): Observable<T> {
    return <any> this.http
      .patch(`${this.apiUrl}${this.url}`, obj)
      .pipe(
        tap(() => {}),
        catchError(this.handleErrors)
      );
  }
  public updatePartialId(Id: any, obj?: Partial<T>): Observable<T> {
    return <any> this.http
      .patch(`${this.apiUrl}${this.url}${Id}`, obj)
      .pipe(
        tap(() => {}),
        catchError(this.handleErrors)
      );
  }
  public delete(obj?: Partial<T>): Observable<T> {
    return <any> this.http
      .delete(`${this.apiUrl}${this.url}`, obj)
      .pipe(
        tap(() => {}),
        catchError(this.handleErrors)
      );
  }
  public deleteId(Id: any): Observable<T> {
    return <any> this.http
      .delete(`${this.apiUrl}${this.url}${Id}`)
      .pipe(
        tap(() => {}),
        catchError(this.handleErrors)
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  protected handleError<K> (operation = 'operation', result?: K) {
    return (error: any): Observable<K> => {
      if (error.status >= 500 || error.status < 400) {
        // TODO: send the error to remote logging infrastructure
        console.error(error); // log to console instead
        // TODO: better job of transforming error for user consumption
        console.log(`${operation} failed: ${error.message}`);
        // Let the app keep running by returning an empty result.
        return of(result as K);
      }
      return _throw(error);
    };
  }

  protected handleErrors(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // Se ha producido un error por parte del cliente o de la red. ManÃ©jalo como corresponde.
      console.error('An error occurred:', error.error.message);
    } else {
      // El backend devolviÃ³ un cÃ³digo de respuesta fallido.
      // El cuerpo de respuesta puede tener pistas de lo que saliÃ³ mal,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    // devolver un observable con un mensaje de error orientado al usuario
    return throwError(error);
  }
}
