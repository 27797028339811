import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CoreConfig, UpdateQueryModel } from "../../models";
import { Service } from "../service";
import { OAuthService } from "angular-oauth2-oidc";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class QueryConsultsService extends Service<UpdateQueryModel[]>{
  protected url = 'v1/query/';
  protected digest = `Mh6ajPzhkEbNSf8PTL5/qIRogoPYlwXOmYQeOJghcEM=`;
  // headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

  lastData: any;
  constructor(http: HttpClient, config: CoreConfig,  private oauthService: OAuthService) {
    super(http, config);
  }
  protected tokens = this.oauthService.getAccessToken();
  headers = new HttpHeaders({
    "Authorization": `Bearer ${this.tokens}`,
    "Bepartners-Auth": "Digest Mh6ajPzhkEbNSf8PTL5/qIRogoPYlwXOmYQeOJghcEM=",
    'Content-Type' : 'application/json; charset=utf-8'
  });

  public AddQueryConsults(updateQueryModel: UpdateQueryModel[]): Observable<any> {
    const uri = `${this.apiUrl}${this.url}`;
    return this.http.post(uri, updateQueryModel, {headers: this.headers});
  }

}
