import {Injectable} from '@angular/core';
import {Service} from '../service';
import {HttpClient} from '@angular/common/http';
import {EnterpriseModel} from '../../models/index';
import {Observable, Subscriber} from 'rxjs';
import {CoreConfig} from "../../models/core.config";

@Injectable()
export class PurchaseSummaryService extends Service<EnterpriseModel> {

  protected url = 'v1/enterprise/';

  public purchaseSummary$: Observable<EnterpriseModel[]>;
  private purchaseSummarySubscriber: Subscriber<EnterpriseModel[]>;
  private purchaseSummary: EnterpriseModel[];

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
    this.purchaseSummary$ = new Observable<EnterpriseModel[]>(subscriber => {
      this.purchaseSummarySubscriber = subscriber;
    });
  }

  /**
   * AÃ±adir compra
   */
  addEnterpriseReport(enterprise: EnterpriseModel) {
    if (typeof(Storage) === 'undefined') {
      alert('Sorry! No Web Storage support..');
    } else {
      const objEnterprise = {
        'Enterprise': [{
          'Name': enterprise.Name,
          'Nit': enterprise.Nit,
          'Industry': enterprise.Industry,
          'City': enterprise.City,
          'CreationDate': enterprise.CreationDate,
          'Description': enterprise.Description,
          'Type': 'EnterpriseReport'
        }]
      };
      return this.addItemSessionStorage(objEnterprise);
    }
  }

  /**
   * AÃ±adir reporte
   */
  addExpressReport(enterprise: EnterpriseModel) {
    if (typeof(Storage) === 'undefined') {
      alert('Sorry! No Web Storage support..');
    } else {
      const objEnterprise = {
        'Enterprise': [{
          'Name': enterprise.Name, 'Nit': enterprise.Nit, 'Industry': enterprise.Industry,
          'City': enterprise.City, 'CreationDate': enterprise.CreationDate, 'Description': enterprise.Description, 'Type': 'ExpressReport'
        }]
      };
      return this.addItemSessionStorage(objEnterprise);
    }
  }

  /**
   * AÃ±adir sesion al storage
   */
  addItemSessionStorage(obj: any) {
    if (typeof(Storage) !== 'undefined') {
      const summary = sessionStorage.getItem('PurchaseSummary');
      if (summary != null) {
        const json = JSON.parse(summary);
        /*==Verify if it already exists==*/
        if (json['Enterprise'].find(i => i.Nit === obj['Enterprise'][0].Nit && i.Type === obj['Enterprise'][0].Type)) {
          return false;
        }
        /*==============================*/
        json['Enterprise'].push(obj['Enterprise'][0]);
        sessionStorage['PurchaseSummary'] = JSON.stringify(json);
      } else {
        sessionStorage['PurchaseSummary'] = JSON.stringify(obj);
      }
      this.purchaseSummary = JSON.parse(sessionStorage.getItem('PurchaseSummary'));
      if(this.purchaseSummarySubscriber) {
        this.purchaseSummarySubscriber.next(this.purchaseSummary);
      }
    } else {
      alert('Sorry! No Web Storage support..');
    }
  }

  /**
   * Eliminar item
   */
  removeItem(enterprise: EnterpriseModel, type: string) {
    this.purchaseSummary['Enterprise'].splice(this.purchaseSummary['Enterprise'].findIndex(i => i.Nit === enterprise.Nit && i.Type === type), 1);
    sessionStorage['PurchaseSummary'] = JSON.stringify(this.purchaseSummary);

    this.purchaseSummary = JSON.parse(sessionStorage.getItem('PurchaseSummary'));
    if(this.purchaseSummarySubscriber) {
      this.purchaseSummarySubscriber.next(this.purchaseSummary);
    }
  }
}
