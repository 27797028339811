export class AccessGrantModels {
  IsAccepted: boolean;
  Description: string;
  Id: string;
  constructor() {
    this.IsAccepted = true;
    this.Description = null;
    this.Id = null;
  }
}
