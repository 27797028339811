import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import { DiscountOutputReportModel } from './../../modelsB/';
import { CoreConfig } from "../../models";
import { Service } from "../../services";
const httpOptionsFile = {
  headers: new HttpHeaders({'Content-Type': 'application/json', responseType: 'blob'})
};

@Injectable()
export class DiscountReportService  extends Service<DiscountOutputReportModel> {
  protected url = 'v1/DiscountReport/AllDiscountsByDateRanges';
  lastData: any;

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

  /**
   * Traer datos de los descuentos por fechas
   * @param initdate fecha inicial del reporte
   * @param endDate fecha final del reporte
   */
  getReportDiscount(initdate: any, endDate: any): Observable<DiscountOutputReportModel>  {
    return this.http.get<DiscountOutputReportModel>(`${this.apiUrl}${this.url}?StartDate=${initdate}&EndDate=${endDate}`).pipe(
      map(result => result),
      tap((result) => {
        this.lastData = result;
      }),
      catchError(this.handleErrors)
    );
  }

  /**
   * Traer datos para descargar luego en excel
   * @param initdate fecha inicial
   * @param endDate fecha final
   */
  downloadReportDiscount(initdate: any, endDate: any): Observable<any> {
    // Este servicio cambia en los headers porque tiene que ser tipo Blob para poder comvertir el json a excel en el subscribe
    return this.http.get<DiscountOutputReportModel>(`${this.apiUrl}${this.url}?StartDate=${initdate}&EndDate=${endDate}`, httpOptionsFile).pipe(
      map(result => result),
      tap((result) => {
        this.lastData = result;
      }),
      catchError(this.handleErrors)
    );
  }
}
