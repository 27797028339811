export class UserTransactionsModel {
  TotalAmount: number;
  TransactionState: any;
  TransactionId: number;
  TrazabilityCode: number;
  BankName: string;
  PayCurrency: string;
  IdentificationNumber: number;

  constructor() {
    this.TotalAmount = null;
    this.TransactionState = null;
    this.TransactionId = null;
    this.TrazabilityCode = null;
    this.BankName = null;
    this.PayCurrency = null;
    this.IdentificationNumber = null;
  }
}

