import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CoreConfig, EnterpriseFilterModel } from "../../../models";
import { Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { Service } from "../../service";

@Injectable({
  providedIn: 'root'
})

export class ProductEnterpriseFilterService extends Service<EnterpriseFilterModel>{
  currentData: any;
  protected url = "v1/Enterprise/";
  headers = new HttpHeaders().set("Content-Type", "application/json; charset=utf-8");

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

  /**
   * GET enterprise whose name contains search term
   */
  searchProductEnterpriseNet (term: string, page: number, pageSize: number, sortBy: string, nitOrName?: any, industry?: string, location?: string): Observable<EnterpriseFilterModel[]> {
    // clear if no pkg name
    if (!term.trim()) { return of([]); }

    // TODO: Add error handling
    return this.http.get<EnterpriseFilterModel[]>(`${this.apiUrl}${this.url}GetEnterprisesByFilter?SearchCriteria=${term}&Industry=${industry}&City=${location}&NitOrName=${nitOrName}&PageSize=${pageSize}&Page=${page}&SortBy=${sortBy}`).pipe(
      catchError(this.handleErrors)
    );
  }

}
