import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import {Observable, of, throwError as _throw,  throwError } from 'rxjs';
import {catchError, tap, } from 'rxjs/operators';
import {CoreConfig} from "../models";
import swal from 'sweetalert2';

/**
 * Estos servicios son genÃ©ricos que se presentar en todos servicios para no duplicar las mediciones
 */
export abstract class ServiceBackOffice<T> {
  protected url: string;
  protected readonly apiUrl: string;

  constructor(protected http: HttpClient, config: CoreConfig ) {
    this.apiUrl = config.apiUrl;
  }

  /**
   * traer datos
   */
  public get(query?: Partial<T>, select?: string[]): Observable<T[]> {
    let params = new HttpParams();
    if (query) {
      params = params.append('query', JSON.stringify(query));
    }
    if (select) {
      params = params.append('select', select.join(','));
    }
    return <any> this.http
      .get( `${this.apiUrl}${this.url}`, {params : params})
      .pipe(
        tap(() => {}),
        catchError(this.handleErrors)
      );
  }

  /**
   * traer datos por paginado
   */
  public getPages(query?: any, select?: string[], page?: any, pageSize?: any): Observable<T[]> {
    let params = new HttpParams();
    if (select && query) {
      params = params.append('query.EnterpriseId', query);
      params = params.append('select', select.join(','));
      params = params.append('Page', page);
      params = params.append( 'PageSize', pageSize);
    }
    return <any> this.http
      .get( `${this.apiUrl}${this.url}?`, {params : params})
      .pipe(
        tap(() => {}),
        catchError(this.handleErrors)
      );
  }

  /**
   * Traer por id
   */
  public getById(id: any): Observable<T> {
    return <any> this.http
      .get(`${this.apiUrl}${this.url}${id}`);
  }

  /**
   * Agregar modelo
   */
  public post(obj?: Partial<T>): Observable<T> {
    return  this.http
      .post<any>(`${this.apiUrl}${this.url}`, obj )
      .pipe(
        tap(() => {}),
        catchError(this.handleErrors)
      );
  }

  /**
   * Actualizar datos
   */
  public update(obj?: Partial<T>): Observable<T> {
    return <any> this.http
      .put(`${this.apiUrl}${this.url}`, obj)
      .pipe(
        tap(() => {}),
        catchError(this.handleErrors)
      );
  }

  /**
   * Actualizar modelo con patch
   */
  public updatePartial(obj?: Partial<T>): Observable<T> {
    return <any> this.http
      .patch(`${this.apiUrl}${this.url}`, obj)
      .pipe(
        tap(() => {}),
        catchError(this.handleErrors)
      );
  }

  /**
   * Eliminar modelo
   */
  public delete(obj?: Partial<T>): Observable<T> {
    return <any> this.http
      .delete(`${this.apiUrl}${this.url}`, obj)
      .pipe(
        tap(() => {}),
        catchError(this.handleErrors)
      );
  }


  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  protected handleError<K> (operation = 'operation', result?: K) {
    return (error: any): Observable<K> => {
      if (error.status > 401 && error.status <= 521) {
        // TODO: send the error to remote logging infrastructure
        console.error(error); // log to console instead
        // TODO: better job of transforming error for user consumption
        console.log(`${operation} failed: ${error.message}`);
        // Let the app keep running by returning an empty result.
        swal({
          type: 'error',
          title: 'Ha ocurrido un error!',
          text: 'Por favor intente de nuevo mÃ¡s tarde.',
          confirmButtonText: 'Aceptar',
          confirmButtonAriaLabel: 'Aceptar',
          allowEscapeKey: false,
          allowEnterKey: false,
          allowOutsideClick: false,
          showConfirmButton: false
        });
        return of(result as K);
      }
      return _throw(error);
    };
  }
  private handleErrors(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // Se ha producido un error por parte del cliente o de la red. ManÃ©jalo como corresponde.
      console.error('An error occurred:', error.error.message);
    } else {
      // El backend devolviÃ³ un cÃ³digo de respuesta fallido.
      // El cuerpo de respuesta puede tener pistas de lo que saliÃ³ mal,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    // devolver un observable con un mensaje de error orientado al usuario
    return throwError('Algo malo sucediÃ³; por favor, intÃ©ntelo de nuevo mÃ¡s tarde.');
  }
  /*
  // Opcional para Angular 6
  private handleErrors(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // Se ha producido un error por parte del cliente o de la red. ManÃ©jalo como corresponde.
      console.error('An error occurred:', error.error.message);
    } else {
      // El backend devolviÃ³ un cÃ³digo de respuesta fallido.
      // El cuerpo de respuesta puede tener pistas de lo que saliÃ³ mal,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    if (error.status >= 500 || error.status < 400) {
      console.log(error);
      console.log(`fallo: ${error.message}`);
    }
    // devolver un observable con un mensaje de error orientado al usuario
    return throwError('Algo malo sucediÃ³; por favor, intÃ©ntelo de nuevo mÃ¡s tarde.');
  }
  */
}
