import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import { Service } from "../../services/service";
import { CoreConfig } from "../../models";
import {Observable} from 'rxjs';
import { UserModel } from "../../models/User/user.model";
import { catchError, map, tap } from "rxjs/operators";

@Injectable()

export class UserEnterpriseService extends Service<UserModel> {
  public url = 'v1/User/GetAll/';
  lastData: any;

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

  /**
   * Traer todos los datos de las empresas or usuario
   */
  getAll(initdate: any, endDate: any): Observable<UserModel> {
    return this.http.get<UserModel>(`${this.apiUrl}${this.url}GetAll/?StartDate=${initdate}&EndDate=${endDate}`).pipe(
      map(result => result),
      tap((result) => {
        this.lastData = result;
      }),
      catchError(this.handleErrors)
    );
  }
}
