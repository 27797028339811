export class RegisterModel {
  BussinesName: string;
  Nit: string;
  VerificationCode: string;
  FirstName: string;
  MiddleName: string;
  SurName: string;
  SecondSurName: string;
  Email: string;
  IdentificationType: string;
  IdentificationNumber: string;
  ExpeditionDate: string;
  CellPhone: string;
  Password: string;
  ConfirmPassword: string;
  IdentificationTypeEnterprise: string;


  constructor() {
    this.BussinesName = null;
    this.Nit = null;
    this.VerificationCode = null;
    this.FirstName = null;
    this.MiddleName = null;
    this.SurName = null;
    this.SecondSurName = null;
    this.Email = null;
    this.IdentificationType = null;
    this.IdentificationNumber = null;
    this.ExpeditionDate = null;
    this.CellPhone = null;
    this.Password = null;
    this.ConfirmPassword = null;
    this.IdentificationTypeEnterprise = null;
  }
}
