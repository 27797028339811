import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import { CoreConfig } from "../../../models/core.config";
import { Service } from "../../../services";
import { RestrictiveListModel, ScoreModel } from "../../../modelsB";

const httpOptionsFile = {
  headers: new HttpHeaders({'Content-Type': 'application/json', responseType: 'blob'})
};

@Injectable()
export class RestrictiveListErrorService extends Service<RestrictiveListModel> {

  protected url = 'v1/Experian/GetErrorRestrictiveListByDateRange';
  lastData: any;

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

}
