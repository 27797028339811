import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {CoreConfig} from "../../models/index";
import {Service} from "../../services/service";
import {AreaModel} from "../../modelsB/areas/area.model";

@Injectable()

export class AreasService extends Service<AreaModel> {

  public url = 'v1/Area/';

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }
}
