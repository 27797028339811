import { Address } from './address';
import { Phones } from './phones';
import { Identification } from './identification';
import { ChamberCommerce } from './chamberCommerce';

export class BasicInformation {
  CompanyName: string;
  Acronym: string;
  Identification: Identification;
  VerificationDigit: number;
  ChamberCommerceRegistration: ChamberCommerce;
  RegistrationNumber: number;
  DateRegistration: string;
  RegistrationRenewalDate: string;
  EffectiveDate: string;
  StateRegistration: string;
  TypeCompany: string;
  TypeOrganization: string;
  TuitionCategory: string;
  NumberEmployees: number;
  CIIUcode: string;
  EconomicActivity: string;
  Address: Address;
  Phone: Phones;
  Email: string;
  WebPage: string;


  constructor() {
    this.CompanyName = null;
    this.Acronym = null;
    this.VerificationDigit = null;
    this.RegistrationNumber = null;
    this.DateRegistration = null;
    this.RegistrationRenewalDate = null;
    this.EffectiveDate = null;
    this.StateRegistration = null;
    this.TypeCompany = null;
    this.TypeOrganization = null;
    this.TuitionCategory = null;
    this.NumberEmployees = null;
    this.CIIUcode = null;
    this.EconomicActivity = null;
    this.Email = null;
    this.WebPage = null;
  }
}
