import {Injectable} from '@angular/core';
import {Service} from '../service';
import {HttpClient} from '@angular/common/http';
import {PackageModel, ProductModel} from '../../models/index';
import {Observable} from 'rxjs';
import {catchError, tap, map} from 'rxjs/operators';
import {CoreConfig} from "../../models/core.config";

@Injectable()
export class ProductService extends Service<ProductModel> {

  public url = 'v1/product/';

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

  /**
   * Traer todos los paquetes
   */
  public getPackages(productId: string): Observable<PackageModel[]> {
    const uri = `${this.apiUrl}${this.url}${productId}/package`;
    return <any> this.http
      .get(uri)
      .pipe(
        catchError(this.handleErrors)
      );
  }

  /**
   * Traer solo paquetes activados
   */
  public getAllIsActive(): Observable<ProductModel> {
    return this.http.get<ProductModel>(`${this.apiUrl}${this.url}?Query.ProductStatus=1`).pipe(
      map(result => result),
      tap(() => {}),
      catchError(this.handleErrors)
    );

  }
}
