import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { CoreConfig, RecommendationModel } from "../../../models";
import { Service } from "../../service";



@Injectable({
  providedIn: 'root'
})
export class RecommendationService extends Service<RecommendationModel>{
  public url = 'v1/Recommend/';

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

}
