import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SalesPerDayModel} from '../../modelsB/';
import {catchError, map, tap} from 'rxjs/operators';
import { CoreConfig } from "../../models/core.config";
import { Service } from "../../services";

@Injectable()
export class TransactionsService  extends Service<SalesPerDayModel> {
  protected url = 'v1/PurchaseReport/GetAllPurchaseDescriptionByDateRange';
  lastData: any;

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

  /**
   * Traer transacciones desde un rango de fechas
   */
  getTransactions(initdate: any, endDate: any): Observable<SalesPerDayModel>  {
    return this.http.get<SalesPerDayModel>(`${this.apiUrl}${this.url}?StartDate=${initdate}&EndDate=${endDate}`).pipe(
      map(result => result),
      tap((result) => {
        this.lastData = result;
      }),
      catchError(this.handleErrors)
    );
  }


}
