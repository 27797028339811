import { EnterpriseModel, Identification, Phones } from "..";

export class UserModel {
  Address: string;
  ConfirmPassword: string;
  Email: string;
  Enterprises: EnterpriseModel[];
  ExternalProviderId: number;
  FirstName: string;
  ForgotPasswordToken: string;
  FullName: string;
  Id: string;
  Identification: Identification;
  MiddleName: string;
  Password: string;
  Phones: Phones;
  SecondSurName: string;
  SurName: string;
  Username: string;
  CreatedDate: Date;

  constructor() {
   this.Address = null;
    this.ConfirmPassword = null;
    this.Email = null;
    this.Enterprises = null;
    this.ExternalProviderId = null;
    this.FirstName = null;
    this.ForgotPasswordToken = null;
    this.FullName = null;
    this.Id = null;
    this.Identification = null;
    this.MiddleName = null;
    this.Password = null;
    this.Phones = null;
    this.SecondSurName = null;
    this.SurName = null;
    this.Username = null;
    this.CreatedDate = new Date();
  }
}
