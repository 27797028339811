export class RecommendationModel {
  ArticleId: string;
  EnterpriseIdRecommend: string;
  IsActive: boolean;

  constructor() {
      this.ArticleId = null;
      this.EnterpriseIdRecommend = '00000000-0000-0000-0000-000000000000';
      this.IsActive = false
  }
}
