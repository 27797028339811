import {Injectable} from '@angular/core';
import {Service} from '../service';
import {HttpClient} from '@angular/common/http';
import {DiscountModel} from "../../models/index";
import {catchError} from "rxjs/operators";
import {Observable} from "rxjs";
import {CoreConfig} from "../../models/index";

@Injectable()
export class DiscountService extends Service<DiscountModel> {

  public url = 'v1/Discount/'; // URL to web api

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

  /**
   * GET all Discounts 
   * */
  public getAllDiscounts(): Observable<DiscountModel[]> {
    return this.http.get<DiscountModel[]>(`${this.apiUrl}${this.url}`).pipe(
      catchError(this.handleErrors)
    );
  }

  /**
   * GET data after discount validation 
   * */
  public validateDiscount(packages: string[], discountCode: string, enterpriseId: string, userId: string): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}${this.url}ValidateDiscountCode`, { packages: packages, code: discountCode, enterpriseId: enterpriseId, userId: userId }).pipe(
      catchError(this.handleErrors)
    );
  }

  /**
   * GET Discount by Id
   * */
  public getDiscountById(id: string): Observable<DiscountModel> {
    return this.http.get<DiscountModel>(`${this.apiUrl}${this.url}${id}`).pipe(
      catchError(this.handleErrors)
    );
  }

  /**
   * POST Create a discount
   * */
  public createDiscount(discount: DiscountModel): Observable<DiscountModel> {
    return this.http.post<DiscountModel>(`${this.apiUrl}${this.url}`, discount).pipe(
      catchError(this.handleErrors)
    );
  }

  /** 
   * PATCH modify a Discount by ID
   **/
  public modifyDiscount(discount: DiscountModel): Observable<DiscountModel> {
    return this.http.patch<DiscountModel>(`${this.apiUrl}${this.url}${discount.Id}`, discount).pipe(
      catchError(this.handleErrors)
    );
  }

  /** 
   * DELETE a Discount by ID
   **/
  public deleteDiscount(id: string): any {
    return this.http.delete<any>(`${this.apiUrl}${this.url}${id}`).pipe(
      catchError(this.handleErrors)
    );
  }
}
