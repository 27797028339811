import {Injectable} from "@angular/core";
import {Service} from "../service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {CoreConfig} from "../../models/core.config";
import {catchError, tap} from 'rxjs/operators';
import {Observable} from "rxjs";
import {EvidenteModel, AnswerModel, ResultModel} from "../../models";

@Injectable()

export class EvidenteService extends Service<EvidenteModel[]> {

  public url = 'v1/Evidente/';
  headers =  new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

  /**
   * Traer preguntas de evidente
   * parametro evidenteModel 
   */
  public getQuestions(evidenteModel: EvidenteModel): Observable<ResultModel> {
    return this.http
      .get<any>(`${this.apiUrl}${this.url}GetQuestions?ExpeditionDate=${evidenteModel.ExpeditionDate}&Names=${evidenteModel.Names}&LastName=${evidenteModel.LastName}` +
      `&SecondLastName=${evidenteModel.SecondLastName}&Identification=${evidenteModel.Identification}&IdentificationType=${evidenteModel.IdentificationType}`)
      .pipe(
        tap(() => {}),
        catchError(this.handleErrors)
      );
  }

  /**
   * Validar pregunta de evidente
   * parametro answerModel modelo de la pregunta
   */
  public validateAnswers(answerModel: AnswerModel): Observable<AnswerModel[]> {
    const body = JSON.stringify({
      Answers: answerModel.Answers,
      QuestionnaireId: answerModel.QuestionnaireId,
      Record: answerModel.Record,
      Identification: answerModel.Identification,
      IdentificationType: answerModel.IdentificationType
    });
    return this.http
      .post<any>(`${this.apiUrl}${this.url}ValidateAnswers`, body, {headers: this.headers})
      .pipe(
        tap(() => {}),
        catchError(this.handleErrors)
      );
  }

}
