import { Injectable } from '@angular/core';
import { Service } from "../../services";
import { CoreConfig } from "../../models/core.config";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import {catchError, tap, } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class NotificationsService  extends Service<any> {
  protected url = 'v1/Notification/';
  lastData: any;

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

  /**
   * Traer faltros y notificaciones
   */
  getFiterNotifications(date?: any, typeNotification?: any, relationshipId?: any, page?: any, pageSize?: any): Observable<any>{
    let params = new HttpParams();
    params = params.append('Query.Date', date);
    params = params.append('Query.TypeNotification', typeNotification);
    params = params.append('Query.RelationshipId', relationshipId);
    params = params.append('Page', page);
    params = params.append( 'PageSize', pageSize);
    return this.http.get<any>(`${this.apiUrl}${this.url}?`, {params : params}).pipe(
      tap(() => {}),
      catchError(this.handleErrors)
    )
  }

  /**
   * Actualizar datos de lista
   */
  public updateListData(obj?: any): Observable<any> {
    return <any> this.http
      .patch(`${this.apiUrl}${this.url}`, obj)
      .pipe(
        tap(() => {}),
        catchError(this.handleErrors)
      );
  }

}
