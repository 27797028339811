import { Injectable } from '@angular/core';
import { CoreConfig } from "../../models/core.config";
import { HttpClient } from "@angular/common/http";
import {Service} from '../service';
import { Observable } from "rxjs";
import { PurchaseResult } from "../../models";
import {catchError, tap, map} from 'rxjs/operators';
import { ContactModel } from "../../models/Contact/contact.model";

@Injectable({
  providedIn: 'root'
})
export class ContactService extends Service<any> {

  public url = 'v1/Mail/SendHelpMail/';
  lastData: any;
  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

  /**
   * Enviar email
   */
  postMail(mail: ContactModel): Observable<ContactModel> {
    return this.http.post<ContactModel>(this.apiUrl + this.url, mail).pipe(
      map(result => result),
      tap((result) => {
        this.lastData = result;
      }),
      catchError(this.handleErrors)
    );

  }
}
