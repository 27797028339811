import { Injectable } from "@angular/core";
import { Service } from "../service";
import { CitiesModel } from "../../models";
import { HttpClient } from "@angular/common/http";
import { CoreConfig } from "../../models/core.config";
import { catchError, tap } from "rxjs/operators";
import { Observable } from "rxjs";

@Injectable()

export class CitiesService extends Service<CitiesModel> {

  public url = "v1/Cities/";

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

  /**
   * Traer todas las ciudades
   */
  public getCities(): Observable<CitiesModel[]> {
    const uri = `${this.apiUrl}${this.url}`;
    return this.http
      .get<CitiesModel[]>(uri)
      .pipe(
        tap(() => {}),
        catchError(this.handleErrors)
      );
  }

}
