import { AccessGrantModel } from "./accessGrant.model";

export class ProfileModel {
  Id: string;
  Name: string;
  AccessGrant: AccessGrantModel[];

  constructor() {
    this.Id = null;
    this.Name = null;
    this.AccessGrant = null;
  }
}
