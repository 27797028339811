import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { PackagesModel } from '../../modelsB/';
import {catchError, map, tap} from 'rxjs/operators';
import { CoreConfig } from "../../models/core.config";
import { Service } from "../../services";

@Injectable()
export class ListPackagesService extends Service<PackagesModel> {
   protected url = 'v1/Package/';
  lastData: any;

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

  getPackages(page: number, pageSize: number): Observable<PackagesModel>  {
    return this.http.get<PackagesModel>(`${this.apiUrl}${this.url}?Page=${page}&PageSize=${pageSize}`).pipe(
      map(result => result),
      tap((result) => {
        this.lastData = result;
      }),
      catchError(this.handleErrors)
    );
  }
}
