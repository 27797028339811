import { Injectable } from '@angular/core';
import { Service } from "../service";
import { HttpClient } from "@angular/common/http";
import { CoreConfig, ValidateEnterpriseBlocked } from "../../models";
import { Observable } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ValidateEnterpriseBlockedService extends Service<ValidateEnterpriseBlocked>{
  public lastData: any;
  protected url = "v1/ValidateEnterpriseBlocked/Get";
  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

  /**
   * GET consumo de servicio para validaciÃ³n de empresas bloqueadas para generacion de informes
   * params nit: criterio de validacion para respuesta/notificaciÃ³n a al usuario
   */
  validateEnterpriseBlocked(nit: number): Observable<ValidateEnterpriseBlocked> {
    return this.http.get<ValidateEnterpriseBlocked>(`${this.apiUrl}${this.url}?IdentificationNumber=${nit}`).pipe(
      tap(() => {} ),
      catchError(this.handleErrors)
    )
  }
}
