import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { CoreConfig, PreRegisterModel } from "../../../models";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { Service } from "../../../services";



@Injectable({
  providedIn: 'root'
})
export class PreRegisterReportService extends Service<PreRegisterModel>{
  public url = 'v1/PreRegister/ReportPreRegister';

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

}
