import { Injectable } from '@angular/core';
import { CoreConfig } from "../../models/core.config";
import { Service } from "../../services/service";
import { HttpClient } from "@angular/common/http";
import { AccessGrantModels } from "../../modelsB";

@Injectable({
  providedIn: 'root'
})
export class PermissionsService extends Service<AccessGrantModels> {

  public url = 'v1/permissions/';

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

}
