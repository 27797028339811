export class PurchaseVoucherModel {
  BankName: string;
  IdentificationType: string;
  InvoiceNumber: string;
  PurchaseDate: string;
  PurchaseState: string;
  Total: number;
  TransactionNumber: string;
  UserEmail: string;
  UserIdentificationNumber: string;
  UserName: string;
  UserPhoneNumber: string;
}
