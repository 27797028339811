export class PublicationsNetModel {
  Id: string;
  Logo: string;
  LogoBase64: string;
  EnterpriseId: string;
  Title: string;
  Description: string;
  MediaBase64: string[];
  Media: string[];
  Url: string;
  ArticleParentId: string;
  TypePublication: number;
  CreateDate: Date;
  RecommendCount: number;
  CommentCount: number;
  IsVisible: boolean;
  RecommendForMe: boolean;
  EnterpriseName: string;

  constructor() {
    this.Id = null;
    this.Logo = null;
    this.LogoBase64 = null;
    this.EnterpriseId = '00000000-0000-0000-0000-000000000000';
    this.Title = null;
    this.Description = null;
    this.MediaBase64 = [];
    this.Media = [];
    this.Url = null;
    this.ArticleParentId = '00000000-0000-0000-0000-000000000000';
    this.TypePublication = 0;
    this.CreateDate = new Date();
    this.RecommendCount = 0;
    this.CommentCount = 0;
    this.IsVisible = true;
    this.RecommendForMe = false;
    this.EnterpriseName = null;
  }
}
