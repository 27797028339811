import {Address} from './address';

export class ChamberCommerce {
  Id: string;
  Name: string;
  Address: Address;
  DateRegistration: Date;
  RegistrationNumber: string;
  RegistrationRenewalDate: Date;
  EffectiveDate: Date;
  StateRegistration: string;
  TypeOrganization: string;
  TypeCompany: string;
  TuitionCategory: string;
  NumberEmployees: number;
  EconomicActivity: string;

  constructor() {
    this.Id = null;
    this.Name = null;
    this.RegistrationNumber = null;
    this.Address = null;
    this.DateRegistration = new Date();
    this.RegistrationRenewalDate = new Date();
    this.EffectiveDate = new Date();
    this.StateRegistration = null;
    this.TypeOrganization = null;
    this.TypeCompany = null;
    this.TuitionCategory = null;
    this.NumberEmployees = 0;
    this.EconomicActivity = null;
  }
}
