export class ListProductModel {
  id: number;
  name: string;
  creator: string;
  value: number;
  taxes: number;
  comparativePrice: number;
  status: number;
  price: number;

  constructor() {
    this.id = null;
    this.name = null;
    this.creator = null;
    this.value = null;
    this.taxes = null;
    this.comparativePrice = null;
    this.status = null;
    this.price = null;
  }
}

export enum ProductStatus {
  Inactivo,
  Activo
}
