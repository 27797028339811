export class CorrelationCodeModel {
  CorrelationIdPrimary: string;
  CorrelationIdSecondary: string;
  CorrelationIdInfo: string;
  CorrelationCompanyId: string;

  constructor () {
    this.CorrelationCompanyId = '00000000-0000-0000-0000-000000000000';
    this.CorrelationIdPrimary = null;
    this.CorrelationIdSecondary = null;
    this.CorrelationIdInfo = null;
  }
}
