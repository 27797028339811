import {Injectable} from '@angular/core';
import {Service} from '../service';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {CoreConfig} from "../../models/core.config";
import {GoalModel} from "../../models";

@Injectable()
export class GoalService extends Service<GoalModel> {

  public url = 'v1/Goal/';

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

  public byDate(obj: GoalModel): Observable<GoalModel[]> {
    const uri = `${this.apiUrl}${this.url}`;
    return <any> this.http
      .get(uri)
      .pipe(
        tap(() => {}),
        catchError(this.handleErrors)
      );
  }

  public percentageCompleted(goalId: string): Observable<GoalModel[]> {
    const uri = `${this.apiUrl}${this.url}/goalId/${goalId}`;
    return <any> this.http
      .get(uri)
      .pipe(
        tap(() => {}),
        catchError(this.handleErrors)
      );
  }

}
