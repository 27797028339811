export class PayuData {
    merchantId?: string;
    accountId?: string;
    description?: string;
    referenceCode?: string;
    amount?: string;
    tax?: string;
    taxReturnBase?: string;
    currency?: string;
    signature?: string;
    buyerFullName?: string;
    buyerEmail?: string;
    responseUrl?: string;
    confirmationUrl?: string;
    action?: string;
}