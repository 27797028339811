export class IdentificationModel {
  Number: string;
  IdentificationType: number;
  ExpeditionDate: string;
  ExpeditonPlaceId: string;

  constructor() {
    this.Number = null;
    this.IdentificationType = null;
    this.ExpeditionDate = '0001-01-01T00:00:00.007Z';
    this.ExpeditonPlaceId = '00000000-0000-0000-0000-000000000000';
  }
}
