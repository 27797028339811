import {Phones} from "./phones";
import {Address} from "./address";

export class BranchOfficeModel {
  BranchOfficeId: string;
  Name: string;
  Address: Address;
  Phones: Phones[];
  IsPrincipal: boolean;

  constructor() {
    this.BranchOfficeId = null;
    this.Name = null;
    this.Address = null;
    this.Phones = null;
    this.IsPrincipal = null;
  }
}
