export class ScoreModel {
  IdScore: string;
  Date: Date;
  Hour: string;
  DocumentType: string;
  DocumentNumber: number;
  EnterpriseGuid: string;
  Name: string;
  Result: string;
  Description: string;

  constructor() {
    this.IdScore = null;
    this.Date = null;
    this.Hour = null;
    this.DocumentType = null;
    this.DocumentNumber = null;
    this.EnterpriseGuid = null;
    this.Name = null;
    this.Result = null;
    this.Description = null;
  }
}
