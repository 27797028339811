import { NgModule } from "@angular/core";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import {
  ProductService,
  EnterpriseService,
  CatalogService,
  PurchaseSummaryService,
  LegalRepresentativeService,
  OtpService,
  RuesService,
  DiscountService,
  EvidenteService,
  TermsAndConditionService,
  AcceptanceTermsService,
  GoalService,
  PackageService,
  PurchaseService,
  UserService,
  QueryHistoryService,
  TaxService,
  PurchasedProductService,
  CitiesService,
  ChamberCommerceService,
  ExperianService,
  KeywordsService,
  ReportsService,
  ContactService,
  DownloadReportService,
  PublicationsNetService,
  EnterpriseFilterService,
  RecommendationService,
  CatalogueService,
  CatalogProductService,
  PreRegisterService,
  ProductEnterpriseFilterService,
  ValidateEnterpriseBlockedService,
  QueryConsultsService
} from "./services";

import {
  BidirectionalService,
  ExpirationsReportService,
  DiscountReportService,
  PurchasesReportService,
  CrudPackagesService,
  ListProductsService,
  ListPackagesService,
  TransactionsService,
  TransactionsGraficService,
  ConsumptionsPerCustomerReportService,
  ConsumptionsReportService,
  ConsolidatedSalesReportService,
  MenuService,
  RolesService,
  UserProfileService,
  AreasService,
  ReportsExcelService,
  CrudProductsService,
  PackagesService,
  ProductsService,
  UserEnterpriseService,
  AuthBackOfficeService,
  CouponService,
  PermissionsService,
  RestrictiveListService,
  RestrictiveListErrorIdService,
  RestrictiveListErrorService,
  RestrictiveListIdService,
  ScoreAllService,
  ScoreByIdService,
  ScoreErrorIdService,
  ScoreErrorService,
  AciertaService,
  AciertaIdService,
  UserTransactionsService,
  NotificationsService,
  PublicationsService,
  DetailedConsumptionReportService,
  DetailedMaturityReportService,
  PreRegisterReportService,
  GetPreRegisterService,
  ReportsConsumptionService,
  ReportsErrorBusinessService,
  ReportsErrorExpressService
} from "./servicesB";

import { AuthService } from "./services/Auth/auth.service";
import { OAuthModule } from "angular-oauth2-oidc";
import { AuthInterceptorService } from "./services/Auth/auth-interceptor.service";

@NgModule({
  imports: [
    HttpClientModule,
    OAuthModule.forRoot()
  ],
  providers: [ProductService
    , EnterpriseService
    , PurchaseSummaryService
    , CatalogService
    , LegalRepresentativeService
    , RuesService
    , OtpService
    , DiscountService
    , AuthService
    , EvidenteService
    , TermsAndConditionService
    , AcceptanceTermsService
    , GoalService
    , PackageService
    , PurchaseService
    , QueryHistoryService
    , TaxService
    , UserService
    , PurchasedProductService
    , CitiesService
    , ChamberCommerceService
    , UserProfileService
    , ExperianService
    , KeywordsService
    , BidirectionalService
    , ExpirationsReportService
    , DiscountReportService
    , PurchasesReportService
    , CrudPackagesService
    , ListProductsService
    , ListPackagesService
    , TransactionsService
    , ConsumptionsPerCustomerReportService
    , ConsumptionsReportService
    , ConsolidatedSalesReportService
    , MenuService
    , AreasService
    , RolesService
    , TransactionsGraficService
    , ReportsExcelService
    , CrudProductsService
    , PackagesService
    , ProductsService
    , UserEnterpriseService
    , AuthBackOfficeService
    , ReportsService
    , CouponService
    , ContactService
    , PermissionsService
    , RestrictiveListService
    , ScoreAllService
    , AciertaService
    , UserTransactionsService
    , NotificationsService
    , DownloadReportService
    , PublicationsService
    , PublicationsNetService
    , EnterpriseFilterService
    , RecommendationService
    , CatalogueService
    , CatalogProductService
    , DetailedConsumptionReportService
    , DetailedMaturityReportService
    , ScoreByIdService
    , ScoreErrorIdService
    , ScoreErrorService
    , AciertaIdService
    , RestrictiveListErrorIdService
    , RestrictiveListErrorService
    , RestrictiveListIdService
    , PreRegisterService
    , GetPreRegisterService
    , PreRegisterReportService
    , ProductEnterpriseFilterService
    , ValidateEnterpriseBlockedService
    , ReportsErrorExpressService
    , ReportsErrorBusinessService
    , ReportsConsumptionService
    , QueryConsultsService
    ,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    }
  ]
})
export class CoreModule {
}
