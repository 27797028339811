export class FinancialInformation {
  AccountsReceivable: number;
  Inventories: number;
  ActiveCurrent: number;
  PropertyPlantAndEquipment: number;
  OtherAssets: number;
  Valorizations: number;
  TotalAssets: number;
  PassiveCurrent: number;
  PassiveNotCurrent: number;
  PassiveTotal: number;
  Capital: number;
  NetWorth: number;
  PassiveAndPatrimony: number;
  StatementOfIncome: number;
  OperationalIncome: number;
  SalesCost: number;
  GrossProfit: number;
  OperationalExpenses: number;
  OperationalUtility: number;
  NonOperationalIncome: number;
  NonOperationalExpenses: number;
  TaxesAndSupplements: number;
  EarningsAndLoses: number;
  FinancialIndicators: number;
  CurrentReason: number;
  LeverageOrCapacity: number;
  IndebtednessLevel: number;
  RotationOfTotalAssets: number;
  ProfitMargin: number;
  GrossProfitability: number;
  GrossOperational: number;
  NetProfitability: number;
  ProfitabilityPatrimony: number;
  TotalActiveProfitability: number;
  WorkingCapital: number;

  constructor() {
    this.AccountsReceivable = null;
    this.Inventories = null;
    this.ActiveCurrent = null;
    this.PropertyPlantAndEquipment = null;
    this.OtherAssets = null;
    this.Valorizations = null;
    this.TotalAssets = null;
    this.PassiveCurrent = null;
    this.PassiveNotCurrent = null;
    this.PassiveTotal = null;
    this.Capital = null;
    this.NetWorth = null;
    this.PassiveAndPatrimony = null;
    this.StatementOfIncome = null;
    this.OperationalIncome = null;
    this.SalesCost = null;
    this.GrossProfit = null;
    this.OperationalExpenses = null;
    this.OperationalUtility = null;
    this.NonOperationalIncome = null;
    this.NonOperationalExpenses = null;
    this.TaxesAndSupplements = null;
    this.EarningsAndLoses = null;
    this.FinancialIndicators = null;
    this.CurrentReason = null;
    this.LeverageOrCapacity = null;
    this.IndebtednessLevel = null;
    this.RotationOfTotalAssets = null;
    this.ProfitMargin = null;
    this.GrossProfitability = null;
    this.GrossOperational = null;
    this.NetProfitability = null;
    this.ProfitabilityPatrimony = null;
    this.TotalActiveProfitability = null;
    this.WorkingCapital = null;

  }
}
