import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { CoreConfig, EnterpriseModel, PublicationsNetModel } from "../../../models";
import { Service } from "../../service";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";



@Injectable({
  providedIn: 'root'
})
export class PublicationsNetService extends Service<PublicationsNetModel>{
  public url = 'v1/Article/';

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

}
