export class MetadataModel {
  SeoTittle: string;
  Slug: string;
  MetaDescription: string;
  Keywords: any[];

  constructor () {
    this.SeoTittle = null;
    this.Slug = null;
    this.MetaDescription = null;
    this.Keywords = new Array();
  }
}
