export class LogoModel {
  fileName: string;
  fileBase64: string;
  uniqueId: string;

  constructor() {
    this.fileName = null;
    this.fileBase64 = null;
    this.uniqueId = null;
  }
}
