export class Catalog {
  Id: number;
  value: any;
  label: string;

  constructor() {
    this.Id = 0;
    this.value = null;
    this.label = null;
  }
}
