import {Injectable} from '@angular/core';
import {Service} from '../service';
import {HttpClient} from '@angular/common/http';
import {CoreConfig, LegalRepresentativeModel, EnterpriseRuesModel} from "../../models/index";
import {catchError} from "rxjs/operators";
import {Observable} from "rxjs";

@Injectable()
export class LegalRepresentativeService extends Service<LegalRepresentativeModel> {

  public url = 'v1/rues/'; // URL to web api

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

  /**
   * GET legal representatives by NIT and mercantile register
   * Parametro modelo de empresa
   */
  public getLegalRepresentativesByEnterprise(enterprise: EnterpriseRuesModel): Observable<LegalRepresentativeModel[]> {
    return this.http
      .get<LegalRepresentativeModel[]>(`${this.apiUrl}${this.url}${enterprise.Nit}/${JSON.stringify(enterprise.MercantilRegister)}`)
      .pipe(
      catchError(this.handleErrors)
    );
  }
}
