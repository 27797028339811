export class AlertsModel {
  codigo:string;
  descripcion:string;
  fuente:string;
  constructor() {
     this.codigo = null;
     this.descripcion = null;
     this.fuente = null;
  }
}
