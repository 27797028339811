import {Injectable} from '@angular/core';
import {Service} from '../service';
import {HttpClient} from '@angular/common/http';
import {CoreConfig} from "../../models/core.config";
import {PackageModel} from "../../models";

@Injectable()
export class PackageService extends Service<PackageModel> {

  public url = 'v1/Package/';

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

}
