export class PhoneModel {
  Number: string;
  CityId: string;
  PhoneType: number;

  constructor() {
    this.Number = null;
    this.CityId = null;
    this.PhoneType = 1;
  }
}
