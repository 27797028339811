export class PreRegisterModel {
  Celphone: string;
  ChamberCommerce: string;
  EmployeeFirstName: string;
  EmployeeSecondName: string;
  EmployeeFirstSurname: string;
  EmployeeSecondSurname: string;
  Email: string;
  Identification: string;
  IdentificationEmployee: string;
  IdentificationType: string;
  IdentificationTypeEmployee: string;
  Name: string;
  RegistrationNumber: string;
  CreatedDate: Date;
  IsVerified: boolean
  IsApproved: boolean;

  constructor() {
    this.Celphone = null;
    this.ChamberCommerce = null;
    this.EmployeeFirstName = null;
    this.EmployeeSecondName = null;
    this.EmployeeFirstSurname = null;
    this.EmployeeSecondSurname = null;
    this.Email = null;
    this.Identification = null;
    this.IdentificationEmployee = null;
    this.IdentificationType = null;
    this.IdentificationTypeEmployee = null;
    this.Name = null;
    this.RegistrationNumber = null;
    this.CreatedDate = new Date();
    this.IsVerified = false
    this.IsApproved = false;
  }
}
