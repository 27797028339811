export class PayuConfirm {
        response_code_pol?: string;
        referenceCode?: string;
        phone?: string;
        additional_value?: string;
        test?: string;
        transaction_date?: string;
        cc_number?: string;
        cc_holder?: string;
        error_code_bank?: string;
        billing_country?: string;
        bank_referenced_name?: string;
        description?: string;
        administrative_fee_tax?: string;
        value?: string;
        administrative_fee?: string;
        payment_method_type?: string;
        office_phone?: string;
        email_buyer?: string;
        response_message_pol?: string;
        error_message_bank?: string;
        shipping_city?: string;
        transaction_id?: string;
        sign?: string;
        tax?: string;
        payment_method?: string;
        billing_address?: string;
        payment_method_name?: string;
        pse_bank?: string;
        state_pol?: string;
        date?: string;
        nickname_buyer?: string;
        reference_pol?: string;
        currency?: string;
        risk?: string;
        shipping_address?: string;
        bank_id?: string;
        payment_request_state?: string;
        customer_number?: string;
        administrative_fee_base?: string;
        attempts?: string;
        merchant_id?: string;
        exchange_rate?: string;
        shipping_country?: string;
        installments_number?: string;
        franchise?: string;
        payment_method_id?: string;
        extra1?: string;
        extra2?: string;
        antifraudMerchantId?: string;
        extra3?: string;
        nickname_seller?: string;
        ip?: string;
        airline_code?: string;
        billing_city?: string;
        pse_reference1?: string;
        reference_sale?: string;
        pse_reference3?: string;
        pse_reference2?: string;
}