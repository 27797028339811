import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { CoreConfig } from "../../../models/core.config";
import { Service } from "../../../services";
import { ReportsInf } from "../../../modelsB";

@Injectable({
  providedIn: 'root'
})
export class ReportsErrorExpressService extends Service<ReportsInf> {
  protected url = 'v1/Experian/GetErrorInfExpByDateRanges/';
  lastData: any;

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }
}
