import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { CoreConfig, EnterpriseFilterModel } from "../../../models";
import { Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { Service } from "../../service";

@Injectable({
  providedIn: 'root'
})

export class EnterpriseFilterService extends Service<EnterpriseFilterModel>{
  currentData: any;
  protected url = "v1/Enterprise/";
  headers = new HttpHeaders().set("Content-Type", "application/json; charset=utf-8");

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

  /**
   *  GET enterprise whose name contains search term temporal, se debe eliminar cunado se publique la version backend y hacer referencia en front a searchBusinessDirectoryNit
   * */
  searchBusinessDirectory(term: string, page: any, pageSize: any, sortBy: string): Observable<EnterpriseFilterModel[]> {
    let params = new HttpParams();
      params = params.append('SearchCriteria', term);
      params = params.append('Page', page);
      params = params.append( 'PageSize', pageSize);
      params = params.append( 'SortBy', sortBy);

    // clear if no pkg name
    if (!term.trim()) { return of([]); }
    return this.http.get<EnterpriseFilterModel[]>(`${this.apiUrl}${this.url}BusinessDirectory?`, {params: params}).pipe(
        catchError(this.handleErrors)
      );
  }

  /**
   *  GET enterprise whose name contains search term Temporral
   * */
  searchBusinessDirectoryNit(term: string, page: any, pageSize: any, sortBy: string): Observable<EnterpriseFilterModel[]> {
    let params = new HttpParams();
    params = params.append('Nit', term);
    params = params.append('Page', page);
    params = params.append( 'PageSize', pageSize);
    params = params.append( 'SortBy', sortBy);

    // clear if no pkg name
    if (!term.trim()) { return of([]); }
    return this.http.get<EnterpriseFilterModel[]>(`${this.apiUrl}${this.url}BusinessDirectory?`, {params: params}).pipe(
      catchError(this.handleErrors)
    );
  }


  /**
   * GET enterprise whose name contains search term
   */
  searchEnterpriseNet1 (term: string, page: any, pageSize: any, sortBy: string): Observable<EnterpriseFilterModel[]> {
    let params = new HttpParams();
    params = params.append('SearchCriteria', term);
    params = params.append('Page', page);
    params = params.append( 'PageSize', pageSize);
    params = params.append( 'SortBy', sortBy);

    // clear if no pkg name
    if (!term.trim()) { return of([]); }
    return this.http.get<EnterpriseFilterModel[]>(`${this.apiUrl}${this.url}GetEnterprisesByFilter?`, {params: params}).pipe(
      catchError(this.handleErrors)
    );
  }

}
