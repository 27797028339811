import {Injectable} from '@angular/core';
import {Service} from '../service';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {CoreConfig} from "../../models/core.config";
import {AcceptanceTermsModel} from "../../models";

@Injectable()
export class AcceptanceTermsService extends Service<AcceptanceTermsModel> {

  public url = 'v1/AcceptanceTerms/';

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

  /**
   * Funcion para traer si acepto o no terminos y condiciones en el login
   */
  public test(id: string): Observable<AcceptanceTermsModel[]> {
    const uri = `${this.apiUrl}${this.url}/${id}`;
    return <any> this.http
      .get(uri)
      .pipe(
        tap(() => {}),
        catchError(this.handleErrors)
      );
  }

}
