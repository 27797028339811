import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import { UserProfileModel } from "../../modelsB";
import { Service } from "../../services/service";
import { CoreConfig } from "../../models";

@Injectable()

export class UserProfileService extends Service<UserProfileModel> {

  public url = 'v1/userProfile/';

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }
  
}
