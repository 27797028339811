import { Injectable } from '@angular/core';
import { CatalogProductModel, CoreConfig } from "../../../models";
import { HttpClient } from "@angular/common/http";
import { Service } from "../../service";

@Injectable({
  providedIn: 'root'
})
export class CatalogProductService extends Service<CatalogProductModel>{
  public url = 'v1/CatalogProduct/';

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }
}
