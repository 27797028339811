import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from "rxjs/operators";
import { Observable } from "rxjs";
import { CoreConfig } from "../../models/index";
import { Service } from "../../services";
import { CouponModel } from "../../modelsB/coupons/coupon.model";

@Injectable()
export class CouponService extends Service<CouponModel> {

  public url = 'v1/Discount/'; // URL to web api

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

  /* GET all Discounts */
  public getAllDiscounts(): Observable<CouponModel[]> {
    return this.http.get<CouponModel[]>(`${this.apiUrl}${this.url}`).pipe(
      catchError(this.handleErrors)
    );
  }

  /* GET data after discount validation */
  public validateDiscount(packages: string[], discountCode: string, enterpriseId: string, userId: string): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}${this.url}ValidateDiscountCode`, {
      packages: packages,
      code: discountCode,
      enterpriseId: enterpriseId,
      userId: userId
    }).pipe(
      catchError(this.handleErrors)
    );
  }

  /*GET Discount by Id*/
  public getDiscountById(id: string): Observable<CouponModel> {
    return this.http.get<CouponModel>(`${this.apiUrl}${this.url}${id}`).pipe(
      catchError(this.handleErrors)
    );
  }

  /*POST Create a discount*/
  public createDiscount(discount: CouponModel): Observable<CouponModel> {
    return this.http.post<CouponModel>(`${this.apiUrl}${this.url}`, discount).pipe(
      catchError(this.handleErrors)
    );
  }

  /*PATCH modify a Discount by ID*/
  public modifyDiscount(discount: CouponModel): Observable<CouponModel> {
    return this.http.patch<CouponModel>(`${this.apiUrl}${this.url}${discount.Id}`, discount).pipe(
      catchError(this.handleErrors)
    );
  }

  /*DELETE a Discount by ID*/
  public deleteDiscount(id: string): any {
    return this.http.delete<any>(`${this.apiUrl}${this.url}${id}`).pipe(
      catchError(this.handleErrors)
    );
  }
}
