import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { CoreConfig, PreRegisterModel } from "../../../models";
import { Observable } from "rxjs";
import { catchError, map, tap } from 'rxjs/operators';
import { Service } from "../../../services";



@Injectable({
  providedIn: 'root'
})
export class GetPreRegisterService extends Service<PreRegisterModel>{
  public url = 'v1/PreRegister/GetPreRegister';
  public url2 = 'v1/PreRegister';
  public lastData: any;
  
  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

  AddEnterprice(enterprices: any): Observable<PreRegisterModel> {
    return this.http.patch<any>(this.apiUrl + this.url2, enterprices).pipe(
      map(result => result),
      tap((result) => {
        this.lastData = result;
      }),
      catchError(this.handleErrors)
    );
  }
}
