import { ProfileModel } from  './profile.model';
import { IdentificationModel } from './identification.model';
import { PhoneModel } from "./phone.model";

export class UserProfileModel {
  Name: string;
  Password: string;
  Position: string;
  Email: string;
  AreaId: string;
  Profile: ProfileModel;
  PhotoBase64: string;
  IsActive: boolean;
  AreaName: string;
  SubjectId: string;
  FirstName: string;
  MiddleName: string;
  SurName: string;
  SecondSurName: string;
  Identification: IdentificationModel;
  Phones: PhoneModel;
  Username: string;
  PhotoUrl: string;
  FullName: string;


  constructor() {
    this.Name = null;
    this.Password = null;
    this.Position = null;
    this.Email = null;
    this.AreaId = null;
    this.PhotoBase64 = null;
    this.IsActive = true;
    this.AreaName = null;
    this.SubjectId = null;
    this.FirstName = null;
    this.MiddleName = null;
    this.SurName = null;
    this.SecondSurName = null;
    this.Identification = null;
    this.Phones = null;
    this.Profile = null;
    this.Username = null;
    this.PhotoUrl = null;
    this.FullName = null;
  }

}
