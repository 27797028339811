import {Injectable} from '@angular/core';
import {Service} from '../service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {CoreConfig} from "../../models/core.config";
import {OtpModel} from "../../models";

@Injectable()
export class OtpService extends Service<OtpModel> {

  public url = 'v1/Otp/';
  public options = null;
  headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

  /**
   * Enviar mensaje
   * Parametro usuario al que se le envia
   */
  public sendSms(to: string): Observable<OtpModel[]> {

    const uri = `${this.apiUrl}${this.url}SendSms`;
    const body = JSON.stringify({to: to});

    return this.http
      .post<OtpModel[]>(uri, body, {headers: this.headers})
      .pipe(
        tap(() => {}),
        catchError(this.handleErrors)
      );
  }

  /**
   * Enviar email
   * Parametro nombre del email
   */
  public sendMail(email: string): Observable<OtpModel[]> {
    const uri = `${this.apiUrl}${this.url}SendMail`;
    return this.http
      .post<OtpModel[]>(uri, {email: email}, {headers: this.headers})
      .pipe(
        tap(() => {}),
        catchError(this.handleErrors)
      );
  }

  /**
   * Validacion de codigo en el servicio otp
   */
  public validateOtp(to: string, code: string, otpSendType: string): Observable<number> {
    const uri = `${this.apiUrl}${this.url}ValidateOtp`;
    return <any> this.http
      .post<OtpModel[]>(uri, JSON.stringify({otpSendType, code, to}), {headers: this.headers})
      .pipe(
        tap(() => {}),
        catchError(this.handleErrors)
      );
  }
}
