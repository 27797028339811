import {QuestionAnswer} from "./question-answer.model";

export class QuestionModel {
  CorrectAnswerId: string;
  Id: number;
  Order: number;
  Text: string;
  weight: number;
  Answers: QuestionAnswer[];
}
