export class AnswerModel {
  Answers?: {
    answerId: string;
    questionId: string;
  }[];
  QuestionnaireId: string;
  Record: string;
  Identification: string;
  IdentificationType: string;
}
