import { Injectable, Optional } from "@angular/core";
import { Observable, throwError as _throw } from "rxjs";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpHeaders } from "@angular/common/http";
import { OAuthServerConfig } from "../../models";
import { OAuthModuleConfig, OAuthResourceServerErrorHandler, OAuthService, OAuthStorage } from "angular-oauth2-oidc";
import { catchError } from "rxjs/operators";

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

  constructor(private oauthService: OAuthService,
    @Optional() private oauthConfig: OAuthServerConfig,
    @Optional() private moduleConfig: OAuthModuleConfig,
    private authStorage: OAuthStorage,
    private errorHandler: OAuthResourceServerErrorHandler, ) { }

  /**
   *
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = req.url.toLowerCase();
    if (!this.oauthConfig) {
      return next.handle(req);
    }
    if (!this.oauthConfig.allowedUrls) {
      return next.handle(req);
    }
    if (!this.checkUrl(url)) {
      return next.handle(req);
    }
    const token = this.oauthService.getAccessToken();
    const digest = `Mh6ajPzhkEbNSf8PTL5/qIRogoPYlwXOmYQeOJghcEM=`;

    // if (token) {
    //   // const headers = req.headers.set('Authorization', `Bearer ${token}`);
    //   // var headers = new HttpHeaders({
    //   //   "Authorization": `Bearer ${token}`,
    //   //   "Bepartners-Auth": "Digest Mh6ajPzhkEbNSf8PTL5/qIRogoPYlwXOmYQeOJghcEM=",
    //   //   'Content-Type' : 'application/json; charset=utf-8'
    //   // });
    //   req = req.clone({setHeaders: {
    //       'Authorization' : `Bearer ${token}`,
    //       'Bepartners-Auth' : `Digest ${digest}`,
    //       'Content-Type' : 'application/json; charset=utf-8'
    //     }} );
    // } else {
    //   const tokens = this.oauthService.authorizationHeader();
    //   // var headers = new HttpHeaders({
    //   //   "Authorization": tokens,
    //   //   "Bepartners-Auth": "Digest Mh6ajPzhkEbNSf8PTL5/qIRogoPYlwXOmYQeOJghcEM=",
    //   //   'Content-Type' : 'application/json; charset=utf-8'
    //   // });
    //   req = req.clone({setHeaders: {
    //       'Authorization' : `Bearer ${tokens}`,
    //       'Bepartners-Auth' : `Digest ${digest}`,
    //       'Content-Type' : 'application/json; charset=utf-8'
    //     }} );
    // }


    if (token) {
      req = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + token) });
    }

    if (!req.headers.has('Content-Type')) {
      req = req.clone({ headers: req.headers.set('Content-Type', 'application/json; charset=utf-8') });
    }
    req = req.clone({ headers: req.headers.set('Accept', 'application/json') });
    req = req.clone({ headers: req.headers.set('Bepartners-Auth', `Digest ${digest}`,) });





    //  return next.handle(req).catch(err => this.handleError(err));
    return next.handle(req).pipe(catchError(err => this.handleError(err)));
  }

  /**
   *
   */
  private checkUrl(url: string): boolean {
    const found = this.oauthConfig.allowedUrls.find(u => url.startsWith(u));
    return !!found;
  }

  /**
   *
   */
  private handleError(err: HttpResponse<any>): Observable<any> {
    const url = err.url.toLowerCase();
    if (!this.oauthConfig) {
      return this.next(err);
    }
    if (!this.oauthConfig.allowedUrls) {
      return this.next(err);
    }
    if (!this.checkUrl(url)) {
      return this.next(err);
    }
    if (err.status === 401) {
      // this.oauthService.initImplicitFlow();
      return;
    }
    return this.next(err);
  }

  /**
   *
   */
  private next(err: HttpResponse<any>) {
    return _throw(err);
  }

}
