import { Injectable } from '@angular/core';
import { Catalog } from '../models/';

@Injectable()
export class CatalogService {
  listItems: Catalog[];

  getIndustry(): Catalog[] {
    this.listItems = [];
    this.listItems.push({Id: 0, value: '', label: 'Seleccione...'});
    this.listItems.push({Id: 1, value: 'comercio', label: 'Comercio'});
    this.listItems.push({Id: 2, value: 'transporte', label: 'Transporte'});
    this.listItems.push({Id: 3, value: 'financiera', label: 'Financiera'});
    this.listItems.push({Id: 4, value: 'hotelera', label: 'Hotelera'});
    return this.listItems;
  }

  getLocation(): Catalog[] {
    this.listItems = [];
    this.listItems.push({Id: 0, value: '', label: 'Seleccione...'});
    this.listItems.push({Id: 1, value: 'bogota', label: 'BogotÃ¡'});
    this.listItems.push({Id: 2, value: 'medellin', label: 'MedellÃ­n'});
    this.listItems.push({Id: 3, value: 'cali', label: 'Cali'});
    this.listItems.push({Id: 4, value: 'bucaramanga', label: 'Bucaramanga'});
    this.listItems.push({Id: 5, value: 'florencia', label: 'Florencia'});

    return this.listItems;

  }

  getPagination(): Catalog[] {
    this.listItems = [];
    this.listItems.push({Id: 1, value: 1, label: '1'});
    this.listItems.push({Id: 2, value: 5, label: '5'});
    this.listItems.push({Id: 3, value: 10, label: '10'});
    this.listItems.push({Id: 4, value: 20, label: '20'});
    this.listItems.push({Id: 5, value: 50, label: '50'});
    this.listItems.push({Id: 6, value: 100, label: '100'});
    return this.listItems;
  }
}
