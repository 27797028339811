export class DiscountOutputReportModel {
  CreationDate: Date;
  Area: string;
  CreateBy: string;
  AgreementId: string;
  Code: string;
  UsageNumber: number;
  UsageLimit: number;
  DiscountRate: number;
  DueDate: Date;
  State: string;

  constructor() {
    this.CreationDate = null;
    this.Area = null;
    this.CreateBy = null;
    this.AgreementId = null;
    this.Code = null;
    this.UsageNumber = 0;
    this.UsageLimit = 0;
    this.DiscountRate = 0;
    this.DueDate = null;
    this.State = null;
  }
}
