export class Address {
  Description: string;
  CityId: string;
  Neighborhood: string;

  constructor() {
    this.Description = null;
    this.CityId = null;
    this.Neighborhood = null;
  }
}
