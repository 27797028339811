import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import { ConsolidatedOfSalesReportModel } from './../../modelsB/';
import { CoreConfig } from "../../models/core.config";
import { Service } from "../../services";

const httpOptionsFile = {
  headers: new HttpHeaders({'Content-Type': 'application/json', responseType: 'blob'})
};

@Injectable()
export class ConsolidatedSalesReportService  extends Service<ConsolidatedOfSalesReportModel> {
  protected url = 'v1/PurchaseReport/ReportPurchasesGroupedByPackageAndDiscount';
  lastData: any;

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

  /**
   * Consolodado de ventas por fechas
   * @param initdate 
   * @param endDate 
   */
  getReportConsolidatedSales(initdate: any, endDate: any): Observable<ConsolidatedOfSalesReportModel>  {
    return this.http.get<ConsolidatedOfSalesReportModel>(`${this.apiUrl}${this.url}?StartDate=${initdate}&EndDate=${endDate}`).pipe(
      map(result => result),
      tap((result) => {
        this.lastData = result;
      }),
      catchError(this.handleErrors)
    );
  }

  /**
   * Traer datos del consolidado para descargar en excel
   * @param initdate 
   * @param endDate 
   */
  downloadReportConsolidatedSales(initdate: any, endDate: any): Observable<any> {
    // Este servicio cambia en los headers porque tiene que ser tipo Blob para poder comvertir el json a excel en el subscribe
    return this.http.get<ConsolidatedOfSalesReportModel>(`${this.apiUrl}${this.url}?StartDate=${initdate}&EndDate=${endDate}`, httpOptionsFile).pipe(
      map(result => result),
      tap((result) => {
        this.lastData = result;
      }),
      catchError(this.handleErrors)
    );
  }
}
