export class CatalogProductModel {
  CatalogId: string;
  PhotoBase64: string;
  UrlPhoto: string;
  Name: string;
  Description: string;
  Cover: boolean;
  
  constructor() {
   this.CatalogId = '00000000-0000-0000-0000-000000000000';
   this.PhotoBase64 = null;
   this.UrlPhoto = null;
   this.Name = null;
   this.Description = null;
   this.Cover = false;
  }
}
