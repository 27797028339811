import { Injectable } from "@angular/core";
import { Service } from "../service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EnterpriseModel, CoreConfig, LogoModel } from "../../models/index";
import { Observable } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { OtpModel } from "../../models";

@Injectable()
export class EnterpriseService extends Service<EnterpriseModel> {
  currentData: any;
  protected url = "v1/Enterprise/";
  headers = new HttpHeaders().set("Content-Type", "application/json; charset=utf-8");

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

  /**
   * GET enterprise whose name contains search term
   */
  searchEnterprise(term: string, industry: string, location: string, page: number, pageSize: number, sortBy: string): Observable<EnterpriseModel[]> {
    return this.http
      .get<EnterpriseModel[]>(`${this.apiUrl}${this.url}BusinessDirectory?PageSize=${pageSize}&Page=${page}&NitOrName=${term}&Industry=${industry}&City=${location}&SortBy=${sortBy}`)
      .pipe(
        catchError(this.handleErrors)
      );
  }

  /**
   * Traer datos de la empresa en la busqueda
   */
  searchEnterpriseNet(term: string, page: number, pageSize: number, sortBy: string, nitOrName?: any, industry?: string, location?: string): Observable<EnterpriseModel[]> {
    return this.http
      .get<EnterpriseModel[]>(`${this.apiUrl}${this.url}BusinessDirectory?SearchCriteria=${term}&Industry=${industry}&City=${location}&NitOrName=${nitOrName}&PageSize=${pageSize}&Page=${page}&SortBy=${sortBy}`)
      .pipe(
        catchError(this.handleErrors)
      );
  }

  /**
   * Traer datos de la empresa por nit
   */
  getEnterpriseByNit(nit: any): Observable<EnterpriseModel> {
    return this.http
      .get<EnterpriseModel>(`${this.apiUrl}${this.url}GetEnterpriseByNit/${nit}`)
      .pipe(
        catchError(this.handleErrors)
      );
  }

  /**
   * Traer datos de la empresa por id
   */
  getEnterpriseById(id: string, page: number, pageSize: number): Observable<EnterpriseModel> {
    return this.http
      .get<EnterpriseModel>(`${this.apiUrl}${this.url}${id}?Page=${page}&PageSize=${pageSize}`)
      .pipe(
        catchError(this.handleErrors)
      );
  }

  /**
   * Sobur logo de la empresa
   */
  uploadLogo(data: LogoModel): Observable<LogoModel> {
    return this.http.post<LogoModel>(`${this.apiUrl}${this.url}UploadLogo`, data).pipe(
      map(result => result),
      tap(result => this.currentData = result),
      catchError(this.handleErrors)
    );
  }

  /**
   * Traer todas las empresas
   */
  getAll(): Observable<EnterpriseModel[]> {
    return this.http
      .get<EnterpriseModel[]>(`${this.apiUrl}${this.url}00000000-0000-0000-0000-000000000000?Page=1&PageSize=100`)
      .pipe(
        catchError(this.handleErrors)
      );
  }

  /**
   * Traer terminos y condiciones de la empresa por id
   */
  public termsAndConditions(enterpriseId: string): Observable<any> {

    const uri = `${this.apiUrl}${this.url}TermsAndConditions`;
    const body = JSON.stringify({ enterpriseId: enterpriseId });

    return this.http
      .patch<OtpModel[]>(uri, body, { headers: this.headers })
      .pipe(
        tap(() => {}),
        catchError(this.handleErrors)
      );
  }

  /**
   * Verificar informacion basica de la empresa
   */
  validateBasicInfo(idEnterprise: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}${this.url}ValidateBasicInformation/${idEnterprise}`).pipe(
      tap(() => {}),
      catchError(this.handleErrors)
    );
    
  }


}
