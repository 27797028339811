export class ListPackagesModel {
  Id: string;
  Name: string;
  ExperianPrice: number;
  ClientPrice: number;
  BanlineaRate: number;
  ClientPriceWithTax: number;
  ComparativePrice: number;
  TaxId: number;
  AmountConsults: number;
  ProductId: number;
  Benefits: any[];
  CreationDate: Date;
  PackageCode: string;
  TextAmount: string;
  Order: number;
  ExperianName: string;
  WhiteBackground: boolean;

  constructor() {
    this.Id = null;
    this.Name = null;
    this.ExperianPrice = null;
    this.ClientPrice = null;
    this.BanlineaRate = null;
    this.ClientPriceWithTax = null;
    this.ComparativePrice = null;
    this.TaxId = null;
    this.AmountConsults = null;
    this.ProductId = null;
    this.Benefits = null;
    this.CreationDate = new Date;
    this.PackageCode = null;
    this.TextAmount = null;
    this.Order = null;
    this.ExperianName = null;
    this.WhiteBackground = false;
  }
}

