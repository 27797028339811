import { Injectable } from '@angular/core';
import { PublicationsModel } from "../../modelsB";
import {Service} from '../../services/service';
import { HttpClient } from "@angular/common/http";
import { CoreConfig } from "../../models";
import {catchError} from "rxjs/operators";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PublicationsService extends Service<PublicationsModel>{
  public url = 'v1/Article/';

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

}
