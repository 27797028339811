export class SalesPerDayModel {
  PurchaseDate: Date;
  ClientName: string;
  PackageName: string;
  RegisterNumber: string;
  PurchaseId: string;
  NumberPackagesSold: number;
  Discount: number;
  ExperianPrice: number;
  ClientPrice: number;
  NumberConsumptions: number;
  UnitExperianPrice: number;
  UnitClientPrice: number;
  TotalExperianPrice: number;
  TotalClientPrice: number;
}
