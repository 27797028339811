import { CatalogProductModel } from "./catalogProduct.model";

export class CatalogueModel {
  Id: string;
  EnterpriseId: string;
  Name: string;
  Description: string;
  Published: boolean;
  TotalProducts: number;
  CatalogueProducts: CatalogProductModel[];

  constructor() {
    this.Id = null;
    this.EnterpriseId = null;
    this.Name = null;
    this.Description = null;
    this.Published = false;
    this.TotalProducts = null;
    this.CatalogueProducts = new Array<CatalogProductModel>();
  }
}
