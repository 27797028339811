import {Injectable} from '@angular/core';
import {Service} from '../service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {CoreConfig} from "../../models/core.config";
import {PurchasedProductModel} from "../../models/Product/purchasedProduct.model";

@Injectable()
export class PurchasedProductService extends Service<PurchasedProductModel> {

  public url = 'v1/PurchasedProduct/';

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

  public purchasedProduct(productId: string, enterpriseId: string): Observable<PurchasedProductModel[]> {
    const uri = `${this.apiUrl}${this.url}?ProductId=${productId}&EnterpriseId=${enterpriseId}`;
    return <any> this.http
      .get(uri)
      .pipe(
        catchError(this.handleErrors)
      );
  }
}
