import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { PackagesModel } from '../../modelsB/';
import {catchError, map, tap} from 'rxjs/operators';
import { CoreConfig } from "../../models/core.config";
import { Service } from "../../services";

@Injectable()
export class CrudPackagesService extends Service<PackagesModel> {
  protected url = 'v1/Package/';
  lastData: any;

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

  /**
   * Agregar paquetes
   */
  addPackages(packages: PackagesModel): Observable<PackagesModel>  {
    return this.http.post<PackagesModel>(this.apiUrl + this.url, packages).pipe(
      map(result => result),
      tap((result) => {
        this.lastData = result;
      }),
      catchError(this.handleErrors)
    );
  }
}
