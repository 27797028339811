export class ExpirationReportModel {
  PurchaseDate: Date;
  ClientName: string;
  PackageName: string;
  PurchaseId: string;
  NumberPackagesSold: number;
  Discount: number;
  ExperianPrice: number;
  ClientPrice: number;
  NumberConsumptions: number;
  UnitExperianPrice: number;
  UnitClientPrice: number;
  TotalExperianPrice: number;
  TotalClientPrice: number;

  constructor() {
    this.PurchaseDate = null;
    this.ClientName = null;
    this.PackageName = null;
    this.PurchaseId = null;
    this.NumberPackagesSold = 0;
    this.Discount = 0;
    this.ExperianPrice = 0;
    this.ClientPrice = 0;
    this.NumberConsumptions = 0;
    this.UnitExperianPrice = 0;
    this.UnitClientPrice = 0;
    this.TotalExperianPrice = 0;
    this.TotalClientPrice = 0;
  }
}
