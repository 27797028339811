export class AreaModel {
  Id: string;
  Name: string;
  CreatedBy: string;
  CreationDate: Date;
  IsActive: boolean;
  constructor() {
    this.Id = null;
    this.Name = null;
    this.CreatedBy = null;
    this.CreationDate = new Date();
    this.IsActive = true;
  }
}
