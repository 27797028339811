import {BusinessDirectoryModel} from "./businessDirectory.model";
import { ChamberCommerce } from "../UpdateEnterprise/chamberCommerce";
import {Address} from "../UpdateEnterprise/address";
import {LegalRepresentativeModel} from "../LegalRepresentative/legalRepresentative.model";
import {BranchOfficeModel} from "../UpdateEnterprise/branchOffice.model";
import {LinkedToEnterprises} from "../UpdateEnterprise/likendToEnterprises";
import {FinancialInformation, Phones} from "../";

export enum IdentificationTypeEnum {
  Nit = 2,
  Pasaporte = 3,
  Cc = 1,
  CcExtranjeria = 4
}

export enum EnterpriseRolEnum {
  Customer = 0,
  Owner = 1
}

export class EnterpriseModel {
  Id: string;
  EnterpriseId: number;
  Name: string;
  BusinessName: string;
  Acronym: string;
  IdentificationType: IdentificationTypeEnum;
  Nit: string;
  VerificationCode: string;
  EnterpriseTypeId: number;
  Description: string;
  CreationDate: string;
  Address: Address;
  Phone: Phones[];
  ContactEmail: string;
  CIIUCodePrincipalEconomicActivity: string;
  CIIUCodeSecondaryEconomicActivity: string;
  UrlLogo: string;
  BranchOffice: BranchOfficeModel;
  ChamberCommerceRegistration: ChamberCommerce;
  LegalRepresentatives: LegalRepresentativeModel;
  EnterpriseRol: EnterpriseRolEnum;
  LinkedToEnterprise: LinkedToEnterprises;
  FinancialInformation: FinancialInformation;
  Industry: string;
  Ubication: string;
  City: string;
  BusinessDirectory: BusinessDirectoryModel;
  WebPage: string;

  constructor() {
    this.EnterpriseId = 0;
    this.Name = null;
    this.BusinessName = null;
    this.Acronym = null;
    this.IdentificationType = null;
    this.Nit = null;
    this.VerificationCode = null;
    this.EnterpriseTypeId = null;
    this.Industry = null;
    this.City = null;
    this.CreationDate = null;
    this.Description = null;
    this.Address = null;
    this.Phone = null;
    this.ContactEmail = null;
    this.CIIUCodePrincipalEconomicActivity = null;
    this.CIIUCodeSecondaryEconomicActivity = null;
    this.UrlLogo = null;
    this.BranchOffice = null;
    this.ChamberCommerceRegistration = null;
    this.LegalRepresentatives = null;
    this.EnterpriseRol = null;
    this.LinkedToEnterprise = null;
    this.FinancialInformation = null;
    this.BusinessDirectory = null;
    this.WebPage = null;
    this.Ubication = null;
  }
}
