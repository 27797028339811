export class ContactModel {
  name: string;
  sender: any[];
  subject: string;
  destination: any[];
  message: string;
  constructor() {
    this.name = null;
    this.sender = [''];
    this.subject = null;
    this.destination = [''];
    this.message = null;
  }
}
