export class ReportsInf {
  Guid: string;
  Fecha: string;
  Hora: string;
  Nit: string;
  EmpresaConsulta: string;
  NitConsultado: string;
  EmpresaConsultada: string;
  TipoTransaccion: string;
  ResultadoTransaccion: string;

  constructor() {
   this.Guid = null;
   this.Fecha = null;
   this.Hora = null;
   this.Nit = null;
   this.EmpresaConsulta = null;
   this.NitConsultado = null;
   this.EmpresaConsultada = null;
   this.TipoTransaccion = null;
   this.ResultadoTransaccion = null;
  }

}
