import { Injectable } from "@angular/core";
import { Service } from "../service";
import { TermsAndConditionsModel } from "../../models";
import { HttpClient } from "@angular/common/http";
import { CoreConfig } from "../../models/core.config";
import { Observable } from "rxjs";
import { catchError, tap } from "rxjs/operators";

@Injectable()

export class TermsAndConditionService extends Service<TermsAndConditionsModel> {
  public url = "v1/TermsAndConditions/";

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

  /**
   * Verificar vercion de terminos y condiciones
   */
  public validateLastVersion(enterpriseId: string): Observable<Boolean> {
    const uri = `${this.apiUrl}${this.url}ValidateLastVersion/${enterpriseId}`;
    return this.http
      .get<Boolean>(uri)
      .pipe(
        tap(() => {}),
        catchError(this.handleErrors)
      );
  }
}
