import { Injectable } from '@angular/core';
import { CoreConfig } from "../../models";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Service } from "../service";
import { catchError, map, tap } from "rxjs/operators";
import { Observable } from "rxjs";
const httpOptionsFile = {
  headers: new HttpHeaders({'Content-Type': 'application/ms-excel', responseType: 'arraybuffer'})
};
@Injectable({
  providedIn: 'root'
})
export class ReportsService  extends Service<any>  {

  public url = 'v1/QueryHistory/';
  lastData: any;

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }
  getReports(id: any): Observable<any>  {
    return this.http.get<any>(`${this.apiUrl}${this.url}${id}/ReportFile`).pipe(
      map(result => result),
      tap((result) => {
        this.lastData = result;
      }),
      catchError(this.handleErrors)
    );
  }

  downLoadFile(data: any, type: string) {
    var blob = new Blob([data], { type: type});
    var url = window.URL.createObjectURL(blob);
    var pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
      alert( 'Please disable your Pop-up blocker and try again.');
    }
  }

}
