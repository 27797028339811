import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import { Service } from "../../services";
import { UserTransactionsModel } from "../../modelsB";
import { CoreConfig } from "../../models/core.config";

@Injectable({
  providedIn: 'root'
})
export class UserTransactionsService  extends Service<UserTransactionsModel> {
  protected url = 'v1/Purchase/';
  lastData: any;

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

  /**
   * Traer todas las transacciones 
   */
  getAllUserTransactions(): Observable<UserTransactionsModel>  {
    return this.http.get<UserTransactionsModel>(`${this.apiUrl}${this.url}`).pipe(
      map(result => result),
      tap((result) => {
        this.lastData = result;
      }),
      catchError(this.handleErrors)
    );
  }

}
