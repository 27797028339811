export class Imgbase64Model {
  FileName: string;
  UniqueId: string;
  FileBase64: string | any;

  constructor () {
    this.FileName = null;
    this.UniqueId = null;
    this.FileBase64 = null;
  }
}
