import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { CoreConfig, PreRegisterModel } from "../../../models";
import { Service } from "../../service";
import { Observable } from "rxjs";
import { catchError, map, tap } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class PreRegisterService extends Service<PreRegisterModel>{
  public url = 'v1/PreRegister/';
 

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }
  


}
