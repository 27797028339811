import {BenefitsModel} from './benefits.model';
import {ContainersModel} from './containers.model';
import {MetadataModel} from './metadata.model';
import {CorrelationCodeModel} from './correlationCode.model';
export class ProductModels {
  Id: string;
  CorrelationCode: CorrelationCodeModel;
  Name: string;
  ProductBenefits?: BenefitsModel;
  Containers?: ContainersModel;
  ProductStatus: number;
  CreationDate: Date;
  CreatedBy: string;
  InitialValidityDate: Date;
  FinalValidityDate: Date;
  LinkDemo: string;
  Metadata: MetadataModel;
  AvailableForEnterprise: boolean;
  productCode: number;

  constructor() {
    this.Id = null;
    this.CorrelationCode = new CorrelationCodeModel();
    this.Name = null;
    this.ProductBenefits = new BenefitsModel();
    this.Containers = new ContainersModel();
    this.ProductStatus = 1;
    this.CreationDate = new Date();
    this.CreatedBy = null;
    this.InitialValidityDate = new Date();
    this.FinalValidityDate = null;
    this.LinkDemo = null;
    this.Metadata = new MetadataModel();
    this.AvailableForEnterprise = false;
    this.productCode = 0;
  }
}

export enum ProductsStatus {
  Inactivo,
  Activo
}

export enum ProductCode {
  InformeEmpresarial,
  DirectorioEmpresarial,
  InformeExpress
}
