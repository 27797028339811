export class ValidateEnterpriseBlocked {
  TypeId: string;
  IdentificationNumber: string;
  AmountAccounts: string;
  IndBlocked: string;
  constructor() {
    this.TypeId = null;
    this.IdentificationNumber = null;
    this.AmountAccounts = null;
    this.IndBlocked = null;
  }
}
