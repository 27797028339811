import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import { Service } from "../../service";
import { CoreConfig, KeywordsModel } from "../../../models";


@Injectable()

export class KeywordsService extends Service<KeywordsModel> {

  public url = 'v1/Keywords/';
  public url2 = 'v1/RestrictiveWords'

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

  /**
   * Traer datos de las palagras restringidar y verificar una palabra
   */
  public getText(text){
    return this.http.get(`${this.apiUrl}${this.url2}/` + text)
  }

}
