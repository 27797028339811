import { Injectable } from '@angular/core';
import { Service } from '../service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CoreConfig, QueryPurchaseModel, PayuResponse, PayuConfirm } from "../../models";
import { PurchaseModel, PayuData } from "../../models";
import { PurchaseResult } from "../../models";
import { PurchaseVoucherModel } from "../../models/Purchase/purchaseVoucher.model";

@Injectable()
export class PurchaseService extends Service<PurchaseModel> {

  public url = 'v1/Purchase/';

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

  /**
   * Registrar la nueva transaccion de payu
   */
  public registerPurchase(purchase: PurchaseModel): Observable<PurchaseResult> {
    const uri = `${this.apiUrl}${this.url}RegisterPurchase`;
    return <any>this.http
      .post(uri, purchase)
      .pipe(
        tap(() => { }),
        catchError(this.handleErrors)
      );
  }

  /**
   * 
   */
  public groupedByDate(isAgrupated: boolean, startDate: string, endDate: string): Observable<PurchaseModel[]> {
    const uri = `${this.apiUrl}${this.url}/GroupedByDate?IsAgrupated=${isAgrupated}&StartDate=${startDate}&EndDate=${endDate}`;
    return <any>this.http
      .get(uri)
      .pipe(
        tap(() => { }),
        catchError(this.handleErrors)
      );
  }

  /**
   * 
   */
  public recurringEnterprises(startDate: string, endDate: string): Observable<PurchaseModel[]> {
    const uri = `${this.apiUrl}${this.url}/RecurringEnterprises?StartDate=${startDate}&EndDate=${endDate}`;
    return <any>this.http
      .get(uri)
      .pipe(
        tap(() => { }),
        catchError(this.handleErrors)
      );
  }

  /**
   * Traer cambios en un rango de fechas
   */
  public totalByRangeDate(startDate: string, endDate: string): Observable<PurchaseModel[]> {
    const uri = `${this.apiUrl}${this.url}/RegisterPurchase?StartDate=${startDate}&EndDate=${endDate}`;
    return <any>this.http
      .get(uri)
      .pipe(
        tap(() => { }),
        catchError(this.handleErrors)
      );
  }

  /**
   * Enviar resultado del pago
   */
  public transactionInformation(purchase: PayuResponse): Observable<PurchaseVoucherModel> {
    const uri = `${this.apiUrl}${this.url}TransactionInformation`;
    return <any>this.http
      .post(uri, purchase)
      .pipe(
        tap(() => { }),
        catchError(this.handleErrors)
      );
  }

    /**
   * Enviar resultado del pago
   */
  public transactionConfirmation(purchase: PayuConfirm): Observable<any> {
    const uri = `${this.apiUrl}${this.url}TransactionConfirmation`;
    return <any>this.http
      .post(uri, purchase)
      .pipe(
        tap(() => { }),
        catchError(this.handleErrors)
      );
  }

  /**
   * Traer por datos segun un paginado
   */
  public purchaseQuery(enterpriseId: string, page: number, pageSize: number): Observable<QueryPurchaseModel[]> {
    const uri = `${this.apiUrl}v1/Purchase?Query.EnterpriseId=${enterpriseId}&Select=TotalAmount,TransactionId,BankName,TransactionState,TrazabilityCode,PayCurrency&Page=${page}&PageSize=${pageSize}`;
    return <any>this.http
      .get(uri)
      .pipe(
        tap(() => { }),
        catchError(this.handleErrors)
      );
  }

  /**
   * Validar pago pendiente
   */
  public validatePendingPayments(enterpriseId: string): Observable<boolean> {
    const uri = `${this.apiUrl}v1/Purchase/ValidatePendingPayments/${enterpriseId}`;
    return <any>this.http
      .get(uri)
      .pipe(
        tap(() => { }),
        catchError(this.handleErrors)
      );
  }

  /**
   * Enviar pago pendiente
   */
  public validatePendingPurchasesByEnterprise(enterpriseId: string): Observable<any> {
    const uri = `${this.apiUrl}v1/Purchase/ValidatePendingPurchasesByEnterprise/${enterpriseId}`;
    return <any>this.http
      .post(uri, '')
      .pipe(
        tap(() => { }),
        catchError(this.handleErrors)
      );
  }
}
