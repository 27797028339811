export class ConsultedEnterpriseModel {

  ProductId?: string;
  PackageId?: string;
  ProductName?: string;
  EnterpriseName?: string;
  Nit?: string;
  Industry?: string;
  Ubication ?: string;
  Description ?: string;
}
