export class LinkedToEnterprises {
  LegalReprensentative: string;
  SubstituteLegalRepresentative: string[];
  Partners: string[];
  BoardOfDirectors: string[];
  Executive: string[];
  FiscalReviewer: string[];

  constructor() {
    this.LegalReprensentative = '';
    this.SubstituteLegalRepresentative = new Array();
    this.Partners = new Array();
    this.BoardOfDirectors = new Array();
    this.Executive = new Array();
    this.FiscalReviewer = new Array();
  }
}
