import {Imgbase64Model} from './imgbase64.model';

export class ContainersModel {
  Title: string;
  SubTitle: string;
  Description: string;
  AssociatedImageBase64: string;
  AssociatedImage: string;
  UrlVideo: string;

  constructor () {
    this.Title = null;
    this.SubTitle = null;
    this.Description = null;
    this.AssociatedImage = null;
    this.AssociatedImageBase64 = null;
    this.UrlVideo = null;
  }
}
