import { RestrictionModel } from "./restriction.model";

export class DiscountModel {
  Id: string;
  Code: string;
  Prefix: string;
  Name: string;
  DueDate: Date;
  PackagesId: string[];
  DiscountValue: number;
  DiscountRate: number;
  DiscountType: number;
  Restrictions: RestrictionModel[];
  CreationDate: string
  CreateBy: string;
  InitialValidityDate: Date;
  FinalValidityDate: Date;
  Area: string;
  State: number;
  RealUses: number;

  constructor() {
    this.Id = null;
    this.Code = null;
    this.Prefix = null;
    this.Name = null;
    this.DueDate = null;
    this.PackagesId = [];
    this.DiscountValue = null;
    this.DiscountRate = null;
    this.DiscountType = null;
    this.Restrictions = [];
    this.CreationDate = null;
    this.CreateBy = null;
    this.InitialValidityDate = new Date();
    this.FinalValidityDate = null;
    this.Area = null;
    this.State = null;
    this.RealUses = null;
  }

}
