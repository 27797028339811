import {Injectable} from '@angular/core';
import {Service} from '../service';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {CoreConfig} from "../../models/core.config";
import {RuesModel, LegalRepresentativeModel} from "../../models";

@Injectable()
export class RuesService extends Service<RuesModel> {

  public url = 'v1/rues/';

  constructor(http: HttpClient, config: CoreConfig) {
    super(http, config);
  }

  /**
   * Traer empresa por nit o nombre
   */
  public getEnterprises(enterpriseInput: string): Observable<RuesModel[]> {
    const uri = `${this.apiUrl}${this.url}EnterpriseByNitOrName/${enterpriseInput}`;
    return <any> this.http
      .get(uri)
      .pipe(
        tap(() => {}),
        catchError(this.handleErrors)
      );
  }

  /**
   * Traer datos del representante legal
   */
  public getLegalRepresentatives(nitEnterprise: string, mercantilRegister: string): Observable<LegalRepresentativeModel[]> {
    const uri = `${this.apiUrl}${this.url}LegalRepresentatives/${nitEnterprise}/${mercantilRegister}`;
    return <any> this.http
      .get(uri)
      .pipe(
        tap(() => {}),
        catchError(this.handleErrors)
      );
  }
}
